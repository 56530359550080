import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import posthog from 'posthog-js';
import type { RootState } from 'store/store';
import authApi from './auth.api';
import { AppSettings } from './models/AppSettings';
import { Manufacturer } from './models/Manufacturer';
import { Permission } from './models/Permission';
import { User } from './models/User';

type AuthState = {
    user?: User;
    manufacturer?: Manufacturer;
    isAuthenticated: boolean | null; // if null it means we aren't sure if we are authed or not
    didLogout: boolean;
    didLoginError: boolean;
    didSessionExpire: boolean;
    permissions: Permission[];
    isUpdateAvailable: boolean;
    appSettings?: AppSettings;
};
const getInitialState = (): AuthState => {
    return {
        user: undefined,
        manufacturer: undefined,
        isAuthenticated: null,
        didLogout: false,
        didSessionExpire: localStorage.getItem('didSessionExpire') === 'true',
        didLoginError: false,
        permissions: [],
        isUpdateAvailable: false,
        appSettings: undefined,
    };
};

const handleClearAuth = ({
    didSessionExpire,
    didLoginError,
    isAuthenticated,
    user,
}: {
    didSessionExpire?: boolean;
    didLoginError?: boolean;
    isAuthenticated: boolean | null;
    user?: User;
}) => {
    const state = getInitialState();
    if (didSessionExpire && user) {
        state.didSessionExpire = true;
        localStorage.setItem('didSessionExpire', 'true');
    }
    if (didLoginError) {
        state.didLoginError = true;
    }
    if (!didLoginError && isAuthenticated) {
        state.didLogout = true;
    }
    state.isAuthenticated = false;
    if (window.POSTHOG_ENABLED) {
        posthog.reset();
    }

    return state;
};

const slice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        clearAuth(
            state,
            action: PayloadAction<
                | {
                      didSessionExpire?: boolean;
                      didLoginError?: boolean;
                  }
                | undefined
            >,
        ) {
            return handleClearAuth({
                didSessionExpire: action.payload?.didSessionExpire,
                didLoginError: action.payload?.didLoginError,
                isAuthenticated: state.isAuthenticated,
                user: state.user,
            });
        },
    },
    extraReducers: builder => {
        builder
            // Login succeeded
            .addMatcher(authApi.endpoints.login.matchFulfilled, state => {
                state.isAuthenticated = true;
                state.didSessionExpire = false;
                state.didLoginError = false;
                localStorage.removeItem('didSessionExpire');
            })

            // Me succeeded
            .addMatcher(authApi.endpoints.me.matchFulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.permissions = action.payload.permissions;
                state.user = action.payload.user;
                state.manufacturer = action.payload.manufacturer;

                // store manufacture timezone on window so it can be accessed when needed
                // cant really put this in state because it needs to be accessed by utils
                window.MANUFACTURER_TIMEZONE = action.payload.manufacturer.timezone;
            })

            // Check version succeeded
            .addMatcher(authApi.endpoints.checkVersion.matchFulfilled, (state, action) => {
                state.isUpdateAvailable =
                    action.payload.GIT_COMMIT !== process.env.REACT_APP_GIT_HASH;
            })

            .addMatcher(authApi.endpoints.appSettings.matchFulfilled, (state, action) => {
                state.appSettings = action.payload;
            });
    },
});

export const { clearAuth } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectDidLogout = (state: RootState) => state.auth.didLogout;
export const selectDidSessionExpire = (state: RootState) => state.auth.didSessionExpire;
export const selectDidLoginError = (state: RootState) => state.auth.didLoginError;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectNewVersionAvailable = (state: RootState) => state.auth.isUpdateAvailable;
export const selectCountry = (state: RootState) => state.auth.manufacturer?.country;
export const selectIsUS = (state: RootState) => state.auth.manufacturer?.country.iso2Code === 'US';
export const selectAppSettings = (state: RootState) => state.auth.appSettings;
export const selectUdfs = (entityType: UserDefinedFieldEntityType) => (state: RootState) =>
    state.auth.appSettings?.schemaExtensions[entityType]?.udfs ?? [];
