import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { mergeDeep } from 'utils/helpers';
import inventoryApi from './inventory.api';
import { Inventory } from './models/Inventory';

const initialState = {
    inventoryEditModel: undefined,
    primaryLocationId: localStorage.getItem('primaryLocationId') || '',
} as {
    inventoryEditModel?: Inventory;
    primaryLocationId: string;
};

const slice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setInventoryEditModel(state, action: PayloadAction<Inventory | undefined>) {
            const modelCopy = action.payload
                ? JSON.parse(JSON.stringify(action.payload))
                : undefined;

            state.inventoryEditModel = modelCopy;
        },
        updateInventoryEditModelField(state, action: PayloadAction<Partial<Inventory>>) {
            mergeDeep(state.inventoryEditModel, action.payload);
        },
        setPrimaryLocationId(state, action: PayloadAction<string>) {
            state.primaryLocationId = action.payload;

            if (action.payload) {
                localStorage.setItem(`primaryLocationId`, action.payload);
            } else {
                localStorage.removeItem(`primaryLocationId`);
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(inventoryApi.endpoints.locationList.matchFulfilled, (state, action) => {
            // set primary location id when location list has loaded
            // this will occur when loading for the first time
            // or when the list updates and the current value of primaryLocationId is no longer valid
            if (
                action.payload.length &&
                (!state.primaryLocationId ||
                    !action.payload.some(loc => loc.id === state.primaryLocationId))
            ) {
                // set default primary location id
                // only select from top-level (warehouse) locations
                const defaultLoc = action.payload.find(loc => !loc.parentLocationId);
                if (defaultLoc) {
                    state.primaryLocationId = defaultLoc.id;
                    localStorage.setItem(`primaryLocationId`, defaultLoc.id);
                }
            }
        });
    },
});

export const { setInventoryEditModel, updateInventoryEditModelField, setPrimaryLocationId } =
    slice.actions;

export default slice.reducer;

export const selectInventoryEditModel = (state: RootState) => state.inventory.inventoryEditModel;
export const selectPrimaryLocationId = (state: RootState) => state.inventory.primaryLocationId;
