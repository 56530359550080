import { faker } from '@faker-js/faker';
import CustomerAccessType from '../enums/CustomerAccessType';
import CustomerHoldStatus from '../enums/CustomerHoldStatus';
import CustomerPaymentMethod from '../enums/CustomerPaymentMethod';
import { Brand } from '../models/Brand';
import { Customer, CustomerSchema } from '../models/Customer';
import { CustomerDetail } from '../models/CustomerDetail';

faker.seed(12);
let brandCounter = 0;

function generateFakeBrand(): Brand {
    return {
        id: ++brandCounter,
        name: faker.company.name(),
        isActive: faker.helpers.arrayElement([true, false]),
    };
}

export const fakeBrands = faker.helpers.multiple(generateFakeBrand, {
    count: 12,
});

export function generateCustomerDetail(): CustomerDetail {
    return {
        id: faker.number.int(100),
        countryId: faker.number.int(100),
        accessType: faker.helpers.enumValue(CustomerAccessType),
        companyName: faker.company.name(),
        companyPhone: faker.phone.number(),
        context: {
            mainCompany: {
                id: faker.number.int(100),
                name: faker.company.name(),
                accountId: faker.number.int(100),
                email: faker.internet.email(),
                timezone: faker.location.timeZone(),
                phone: faker.phone.number(),
            },
            customerConfig: {
                isInternal: faker.helpers.arrayElement([true, false]),
                creditLimit: faker.number.int(100),
                creditTerms: faker.word.sample(),
                notes: faker.word.sample(),
                paymentMethod: faker.helpers.enumValue(CustomerPaymentMethod),
                shippingDetails: faker.location.streetAddress(),
                status: faker.helpers.enumValue(CustomerHoldStatus),
                taxApplicable: faker.helpers.arrayElement([true, false]),
                taxExemptions: [
                    {
                        state: faker.location.state({ abbreviated: true }),
                        reason: faker.word.sample(),
                    },
                    {
                        state: faker.location.state({ abbreviated: true }),
                        reason: faker.word.sample(),
                    },
                ],
            },
            address: {
                fullAddress: faker.location.streetAddress(),
            },
            brands: faker.helpers.arrayElements(fakeBrands, { min: 1, max: 10 }),
            userDefinedFields: {},
        },
    };
}

export const fakeCustomerDetails = faker.helpers.multiple(generateCustomerDetail, {
    count: 12,
});

export function getCustomerList() {
    return fakeCustomerDetails.map<Customer>(c => CustomerSchema.parse(c));
}
