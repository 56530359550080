import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import FrontendFilterStrip, {
    FrontendFilterBuilder,
} from 'components/FrontendFilterStrip/FrontendFilterStrip';
import MyButton from 'components/MyButton/MyButton';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import UnitOfMeasureFamily from 'features/inventory/enums/UnitOfMeasureFamily';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryMeasure } from 'features/inventory/models/InventoryMeasure';
import { InventoryMeasureCreateFactory } from 'features/inventory/models/InventoryMeasureCreate';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import {
    InventoryMeasureEdit,
    InventoryMeasureEditFields,
} from '../InventoryMeasureEditFields/InventoryMeasureEditFields';
import './InventoryDetailsMeasures.scss';
import InventoryMeasureStatusBadges from './InventoryMeasureStatusBadge';

const FILTERS = FrontendFilterBuilder<InventoryMeasure>()
    .filter({
        label: 'Search',
        type: 'search',
        defaultValue: '',
        getFields: item => [item.name],
        urlParam: 'search',
    })
    .filter({
        label: 'Archived',
        type: 'toggle',
        defaultValue: 'false',
        urlParam: 'archived',
        getField: item => `${item.isArchived}`,
    })
    .build();

const COLUMNS = ColumnBuilder<InventoryMeasure>()
    .column({
        label: 'Name',
        key: 'name',
        getValue: item => item.name ?? item.unitOfMeasure.name,
    })
    .column({
        label: 'Value',
        key: 'value',
        getValue: item =>
            item.unitOfMeasure.symbol ? `${item.value} ${item.unitOfMeasure.symbol}` : item.value,
    })
    .column({
        label: 'Status',
        key: 'status',
        getValue: item => (
            <InventoryMeasureStatusBadges
                isArchived={item.isArchived}
                isDisplay={item.isDisplayUnit}
            />
        ),
    })
    .column({
        label: 'Purchase',
        key: 'canPurchase',
        getValue: item => item.canPurchase && <Icons.Check size={20} />,
    })
    .column({
        label: 'Sell',
        key: 'canSell',
        getValue: item => item.canSell && <Icons.Check size={20} />,
    })
    .column({
        label: 'Consume',
        key: 'canConsume',
        getValue: item => item.canConsume && <Icons.Check size={20} />,
    })
    .build();

export default function InventoryDetailsMeasures({ model }: { model: Inventory }) {
    const measuresQuery = inventoryApi.useInventoryMeasureListQuery(model.id);

    const [filteredData, setFilteredData] = useState<InventoryMeasure[]>();
    const [editMeasure, setEditMeasure] = useState<InventoryMeasure>();
    const [showCreateMeasure, setShowCreateMeasure] = useState<boolean>(false);

    const addMeasure = useCallback(() => {
        setShowCreateMeasure(true);
    }, []);

    return measuresQuery.isLoading ? (
        <div className="InventoryDetailsModal__TabLoadingPanel">
            <MyLinearProgress />
        </div>
    ) : !measuresQuery.data?.length ? (
        <div className="InventoryDetailsMeasures__Fallback">
            Measures are not configured for this item. Please contact support for assistance.
        </div>
    ) : (
        <>
            <div className="InventoryDetailsMeasures__Toolbar">
                <FrontendFilterStrip
                    data={measuresQuery.data}
                    filters={FILTERS}
                    onChange={setFilteredData}
                >
                    {!model.isArchived && (
                        <MyButton
                            label="Add measure"
                            IconLeft={Icons.Plus}
                            buttonType="Accent"
                            onClick={addMeasure}
                        />
                    )}
                </FrontendFilterStrip>
            </div>

            <DataTable
                className="InventoryDetailsMeasures__Table"
                data={filteredData}
                columns={COLUMNS}
                zebra="light"
                onRowClick={setEditMeasure}
            />

            {showCreateMeasure && (
                <InventoryDetailsMeasuresCreate
                    inventoryId={model.id}
                    family={
                        measuresQuery.data?.[0].unitOfMeasure.family ?? UnitOfMeasureFamily.Length
                    }
                    close={() => setShowCreateMeasure(false)}
                />
            )}
            {editMeasure && (
                <InventoryDetailsMeasuresUpdate
                    inventoryId={model.id}
                    inventoryMeasure={editMeasure}
                    family={editMeasure.unitOfMeasure.family}
                    close={() => setEditMeasure(undefined)}
                />
            )}
        </>
    );
}

function InventoryDetailsMeasuresCreate({
    inventoryId,
    family,
    close,
}: {
    inventoryId: string;
    family: UnitOfMeasureFamily;
    close: () => void;
}) {
    const createModel = useMemo(
        () => InventoryMeasureCreateFactory.create({ inventoryId }),
        [inventoryId],
    );
    const [saveMutation, saveMutationState] = inventoryApi.useInventoryMeasureCreateMutation();

    const save = async (editModel: InventoryMeasureEdit) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            model={createModel}
            editImmediately={true}
            title="Add measure"
            mobileTitle="Measures"
            close={close}
            fullHeight={false}
            onSave={save}
            isSaving={saveMutationState.isLoading}
        >
            {({ editModel, isSaving, updateField }) => (
                <InventoryMeasureEditFields
                    isNew={true}
                    model={editModel}
                    isSaving={isSaving}
                    family={family}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}

function InventoryDetailsMeasuresUpdate({
    inventoryId,
    inventoryMeasure,
    family,
    close,
}: {
    inventoryId: string;
    inventoryMeasure: InventoryMeasure;
    family: UnitOfMeasureFamily;
    close: () => void;
}) {
    const [saveMutation, saveMutationState] = inventoryApi.useInventoryMeasureUpdateMutation();

    const handleSave = async (editModel: InventoryMeasureEdit) => {
        if (editModel.id) {
            await saveMutation({
                id: editModel.id,
                inventoryId,
                canConsume: editModel.canConsume,
                canPurchase: editModel.canPurchase,
                canSell: editModel.canSell,
                name: editModel.name,
            }).unwrap();
        }
        close?.();
    };

    const [archiveMutation, archiveMutationState] =
        inventoryApi.useInventoryMeasureArchiveMutation();

    const dialogManager = useDialogManager();

    const handleArchive = async () => {
        if (inventoryMeasure.id) {
            const confirm = await dialogManager.confirm({
                message: 'Are you sure you want to archive this record?',
                acceptLabel: 'Yes, archive it',
                acceptButtonType: 'Danger',
            });
            if (confirm) {
                await archiveMutation({
                    inventoryId,
                    measureId: inventoryMeasure.id,
                }).unwrap();
                close?.();
            }
        }
    };

    const [unarchiveMutation, unarchiveMutationState] =
        inventoryApi.useInventoryMeasureUnarchiveMutation();

    const handleUnarchive = async () => {
        if (inventoryMeasure.id) {
            await unarchiveMutation({
                inventoryId,
                measureId: inventoryMeasure.id,
            }).unwrap();
        }
    };

    return (
        <MyEditModal
            model={{
                ...inventoryMeasure,
                inventoryId,
                name: inventoryMeasure.name ?? inventoryMeasure.unitOfMeasure.name,
                unitOfMeasure: inventoryMeasure.unitOfMeasure.name,
            }}
            editImmediately={true}
            title={
                <div className="InventoryDetailsMeasuresUpdate__Title">
                    Edit measure{' '}
                    <InventoryMeasureStatusBadges
                        isDisplay={inventoryMeasure.isDisplayUnit}
                        isArchived={inventoryMeasure.isArchived}
                    />
                </div>
            }
            mobileTitle="Measures"
            headerMenuItems={[
                inventoryMeasure.isArchived
                    ? {
                          label: 'Unarchive',
                          IconLeft: Icons.Undo,
                          onClick: handleUnarchive,
                      }
                    : !inventoryMeasure.isDisplayUnit && {
                          label: 'Archive',
                          IconLeft: Icons.Archive,
                          onClick: handleArchive,
                      },
            ]}
            disableMenuWhileEditing={
                archiveMutationState.isLoading || unarchiveMutationState.isLoading
            }
            close={close}
            fullHeight={false}
            onSave={handleSave}
            isSaving={
                saveMutationState.isLoading ||
                archiveMutationState.isLoading ||
                unarchiveMutationState.isLoading
            }
        >
            {({ editModel, isSaving, updateField }) => (
                <InventoryMeasureEditFields
                    isNew={false}
                    model={editModel}
                    isSaving={isSaving}
                    family={family}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}
