enum StepCanTransition {
    FalseAnotherInProgress = 'FALSE_ANOTHER_INPROGRESS',
    FalseBlockingItems = 'FALSE_BLOCKING_ITEMS',
    FalseBlockingSteps = 'FALSE_BLOCKING_STEPS',
    FalseOwnedByAnotherStation = 'FALSE_OWNED_BY_ANOTHER_STATION',
    FalseStateLocked = 'FALSE_STATE_LOCKED',
    FalseDispatchNotAllowed = 'FALSE_DISPATCH_NOT_ALLOWED',
    True = 'TRUE',
}
export default StepCanTransition;
