import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import BackendFilterStrip, {
    BackendFilterBuilder,
} from 'components/BackendFilterStrip/BackendFilterStrip';
import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import { selectIsUS } from 'features/auth/auth.slice';
import { useSalesOrderStatusOptions } from 'features/sales/hooks/useSalesOrderStatusOptions';
import { getCustomerDisplayName, SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi, { SalesOrderListParams } from 'features/sales/sales.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import Icons from 'Icons';
import React, { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { useAppSelector } from 'store/hooks';
import { formatDateRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import SalesOrderStatusBadge from '../SalesOrderStatusBadge/SalesOrderStatusBadge';
import './SalesOrdersTable.scss';

export default function SalesOrdersTable() {
    // retain url query params when navigating
    const [searchParams] = useSearchParams();

    // highlight the currently open item id if any
    const { orderId: activeOrderId } = useParams();

    const refreshData = useApiTagInvalidate([ApiTagType.SalesOrder]);

    const statusOptions = useSalesOrderStatusOptions();

    const isUS = useAppSelector(selectIsUS);

    const columns = useMemo(
        () =>
            ColumnBuilder<SalesOrder>()
                .column({
                    label: 'Order',
                    key: 'manufacturerReference',
                    isSortable: true,
                    defaultSort: 'DESC',
                    getValue: item => item.manufacturerReference,
                })
                .column({
                    label: 'Group',
                    key: 'customerOrderGroup',
                    isSortable: true,
                    whiteSpace: 'break-spaces',
                    getValue: item => item.context.customerOrderGroup.uniqueId,
                    renderValue: val => val.split('-').join('\u2011'), // use non-breaking hyphen
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    isSortable: true,
                    getValue: order => order.context.status,
                    renderValue: (status, order) => (
                        <div className="SalesOrdersTable__StatusCell">
                            <SalesOrderStatusBadge
                                status={status}
                                size="small"
                            />

                            {order.context.isArchived && <ArchivedBadge size="small" />}
                        </div>
                    ),
                })
                .column({
                    label: 'Customer',
                    key: 'customer',
                    isSortable: false,
                    getValue: item => {
                        // fall back to main company name here
                        return getCustomerDisplayName(item) ?? item.context.mainCompany.name;
                    },
                })
                .column({
                    label: 'Received',
                    key: 'createdAt',
                    isSortable: true,
                    defaultSort: 'DESC',
                    whiteSpace: 'nowrap',
                    getValue: item => item.createdAt,
                    renderValue: val => formatDateRelative(val),
                })
                .column({
                    label: 'Sidemark',
                    key: 'sidemark',
                    isSortable: true,
                    getValue: item => item.context.customerPurchaseOrder.sidemark,
                })

                .column({
                    label: 'Items',
                    key: 'itemCount',
                    isSortable: true,
                    align: 'center',
                    getValue: item => item.context.itemCount,
                })
                .column({
                    label: 'Sell Price',
                    key: 'totalSellPrice',
                    isSortable: true,
                    align: 'right',
                    emptyDash: true,
                    getValue: item => item.totalSellPrice,
                    renderValue: val => (val ? formatCurrency(val) : ''),
                })
                .column({
                    label: 'Tax',
                    key: 'totalTax',
                    isSortable: true,
                    align: 'right',
                    emptyDash: true,
                    getValue: item => item.totalTax,
                    renderValue: val => (val ? formatCurrency(val) : ''),
                })
                .column(
                    isUS && {
                        label: 'Tax validated',
                        key: 'taxValidated',
                        isSortable: false,
                        align: 'center',
                        getValue: item => item.taxValidated,
                        renderValue: val =>
                            val ? (
                                <Icons.Check
                                    size={16}
                                    style={{ marginBlock: '-2px' }}
                                />
                            ) : (
                                'No'
                            ),
                    },
                )
                .column({
                    label: 'Due Date',
                    key: 'eta',
                    isSortable: true,
                    defaultSort: 'DESC',
                    whiteSpace: 'nowrap',
                    getValue: item => item.eta,
                    renderValue: val => (val ? formatDateRelative(val) : ''),
                })
                .build(),
        [isUS],
    );

    const criteriaFields = useMemo(
        () =>
            BackendFilterBuilder()
                .filter({
                    label: 'Search',
                    type: 'search',
                    param: 'search',
                    defaultValue: '',
                    urlParam: 'search',
                })
                .filter({
                    label: 'Received',
                    type: 'date',
                    param: 'dateReceived',
                    range: 'past',
                    defaultValue: '',
                    urlParam: 'received',
                })
                .filter({
                    label: 'Status',
                    type: 'select',
                    param: 'statusId',
                    options: statusOptions,
                    urlParam: 'status',
                    allowBlank: true,
                    defaultValue: '',
                })
                .filter({
                    label: 'Archived',
                    type: 'toggle',
                    param: 'isArchived',
                    urlParam: 'archived',
                    defaultValue: 'false',
                })
                .build(),
        [statusOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery<SalesOrderListParams>(criteriaFields);
    const query = salesApi.useSalesOrderListQuery(queryParams, {
        // poll every 5 minutes
        pollingInterval: 300000,
    });

    const handleSortChanged = useCallback(
        (col: DataTableColumn<SalesOrder>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    return (
        <>
            <LayoutHeader>
                <BackendFilterStrip
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                    onRefresh={refreshData}
                    isRefreshing={query.isFetching}
                />
            </LayoutHeader>

            <LayoutBody>
                <DataTable
                    className="SalesOrdersTable__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={query.data?.data}
                    isRefreshing={query.isFetching}
                    rowLinkTo={item => `${item.id}?${searchParams}`}
                    rowIsHighlighted={item => `${item.id}` === activeOrderId}
                    useFrontEndSorting={false}
                    onSortChanged={handleSortChanged}
                    zebra="light"
                    useStickyHeader={true}
                    columns={columns}
                    sortUrlParam="sort"
                />
            </LayoutBody>

            {(query.data?.total ?? 0) > 0 && (
                <LayoutHeader>
                    <DataTablePaging
                        data={paging}
                        totalCount={query.data?.total}
                        onChange={setQueryPaging}
                    />
                </LayoutHeader>
            )}
        </>
    );
}
