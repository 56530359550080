import { User, UserSchema } from 'features/auth/models/User';
import posthog from 'posthog-js';
import { ApiTagType, api } from 'services/api';
import z from 'zod';
import { AppSettings, AppSettingsSchema } from './models/AppSettings';
import { CheckVersionResponse, CheckVersionResponseSchema } from './models/CheckVersionResponse';
import { Manufacturer, ManufacturerSchema } from './models/Manufacturer';
import { Permission } from './models/Permission';
import { UserEdit } from './models/UserEdit';

const authApi = api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<void, { username: string; password: string }>({
            async queryFn({ username, password }, _queryApi, _extraOptions, fetchWithBQ) {
                // login is a 2 step process
                // * Get CSRF token
                // * Use CSRF token to login with username and password
                const csrfResult = await fetchWithBQ({
                    url: `/old/auth/generate-token`,
                    method: 'GET',
                });

                if (csrfResult.error) {
                    return {
                        error: csrfResult.error,
                    };
                }

                const generateTokenResultSchema = z.object({
                    data: z.string(),
                });
                const csrf = generateTokenResultSchema.parse(csrfResult.data).data;

                const authResult = await fetchWithBQ({
                    url: `/old/auth`,
                    method: 'POST',
                    data: {
                        username,
                        password,
                        master_console: false,
                        remember: false,
                        _token: csrf,
                    },
                });

                if (authResult.error) {
                    return {
                        error: authResult.error,
                    };
                }

                // Token is read from cookie set in reponse headers by above query
                // const token = Cookies.get('XSRF-TOKEN') || '';
                return {
                    data: undefined,
                };
            },
            invalidatesTags: (_res, err) => {
                if (err == null) {
                    // only invalidate the session if we successfully logged in
                    return [ApiTagType.UserSession];
                }
                return [];
            },
        }),

        me: build.query<
            {
                user: User;
                permissions: Permission[];
                manufacturer: Manufacturer;
            },
            void
        >({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                // Current user
                const userResult = await fetchWithBQ({
                    url: `/me`,
                    method: 'POST',
                });
                if (userResult.error) {
                    return {
                        error: userResult.error,
                    };
                }

                const meSchema = z.object({
                    user: UserSchema,
                    manufacturer: ManufacturerSchema,
                });

                const meResult = meSchema.parse(userResult.data);

                // Permissions - this is disabled until we can implement properly
                // const permissionResult = (await fetchWithBQ({
                //     url: `/old/employees/permissions`,
                //     method: 'GET',
                //     transformResponse: json => {
                //         const data = JSON.parse(json);
                //         return PermissionsSchema.parse(data.permissions);
                //     },
                // })) as QueryReturnValue<Permission[]>;

                // if (permissionResult.error) {
                //     return {
                //         error: permissionResult.error,
                //     };
                // }

                if (window.POSTHOG_ENABLED) {
                    posthog.identify(`${meResult.user.id}`, {
                        manufacturerId: meResult.manufacturer.id,
                        countryId: meResult.manufacturer.countryId,
                    });
                }
                // user
                return {
                    data: {
                        user: meResult.user,
                        manufacturer: meResult.manufacturer,
                        permissions: [], // permissionResult.data as Permission[],
                    },
                };
            },
            providesTags: [ApiTagType.UserSession],
        }),

        forgotPassword: build.mutation<void, string>({
            async queryFn(email, _queryApi, _extraOptions, fetchWithBQ) {
                const csrfResult = await fetchWithBQ({
                    url: `/old/auth/generate-token`,
                    method: 'GET',
                });

                if (csrfResult.error) {
                    return {
                        error: csrfResult.error,
                    };
                }

                await fetchWithBQ({
                    url: `/old/auth/forgot-password`,
                    method: 'POST',
                    data: {
                        email,
                    },
                });
                return {
                    data: undefined,
                };
            },
        }),

        resetPassword: build.mutation<
            void,
            {
                token: string;
                password: string;
                confirm_password: string;
            }
        >({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const csrfResult = await fetchWithBQ({
                    url: `/old/auth/generate-token`,
                    method: 'GET',
                });

                if (csrfResult.error) {
                    return {
                        error: csrfResult.error,
                    };
                }

                await fetchWithBQ({
                    url: `/old/auth/reset-password`,
                    method: 'POST',
                    data: args,
                });
                return {
                    data: undefined,
                };
            },
        }),

        logout: build.mutation<void, void>({
            query: () => ({
                url: '/old/auth/logout',
                method: 'POST',
            }),
        }),

        userEdit: build.mutation<void, UserEdit>({
            query: model => ({
                url: `/old/users`,
                method: 'POST',
                data: model,
            }),
            invalidatesTags: [ApiTagType.UserSession],
        }),

        checkVersion: build.query<CheckVersionResponse, void>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const url = `${window.location.origin}/health.json`;
                const result = await fetchWithBQ({
                    url,
                    method: 'GET',
                });

                if (result.error) {
                    return {
                        error: result.error,
                    };
                }

                return {
                    data: CheckVersionResponseSchema.parse(result.data),
                };
            },
            providesTags: [ApiTagType.AppVersion],
        }),

        appSettings: build.query<AppSettings, void>({
            query: () => ({
                url: `/settings`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                return AppSettingsSchema.parse(result);
            },
        }),
    }),
});

export default authApi;
