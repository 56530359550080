import MyButton from 'components/MyButton/MyButton';
import React, { useCallback } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './BackendFilterStrip.scss';

import FilterStrip from 'components/FilterStrip/FilterStrip';
import { AutocompleteFilterConfig } from '../FilterStrip/Filters/AutocompleteFilter';
import { DateFilterConfig } from '../FilterStrip/Filters/DateFilter';
import { SearchFilterConfig } from '../FilterStrip/Filters/SearchFilter';
import { SelectFilterConfig } from '../FilterStrip/Filters/SelectFilter';
import { TextFilterConfig } from '../FilterStrip/Filters/TextFilter';
import { ToggleFilterConfig } from '../FilterStrip/Filters/ToggleFilter';
import { FilterInstance } from '../FilterStrip/FilterTypes';

export type BackendFilterStripConfig = (
    | TextFilterConfig
    | SearchFilterConfig
    | SelectFilterConfig
    | AutocompleteFilterConfig
    | (DateFilterConfig & { blankValue?: string })
    | ToggleFilterConfig
) & {
    param: string;
};

export function BackendFilterBuilder() {
    const _filters: Array<BackendFilterStripConfig> = [];
    return {
        filter(f: false | BackendFilterStripConfig) {
            if (f) {
                // Display filters by default, unless explicitly set to false
                if (f.isSticky === undefined) {
                    f.isSticky = true;
                }
                _filters.push(f);
            }
            return this;
        },
        build() {
            return _filters;
        },
    };
}

export default function BackendFilterStrip({
    className,
    fields: fieldDefs,
    onChange,
    onRefresh,
    isRefreshing,
    children,
}: {
    className?: string;
    fields: (BackendFilterStripConfig | false)[];
    onChange?: (params: any) => void;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    children?: React.ReactNode;
}) {
    const generateParams = useCallback(
        (activeFilters: FilterInstance<BackendFilterStripConfig>[]) => {
            const params = {} as any;
            activeFilters.forEach(f => {
                if (f.config.param) {
                    params[f.config.param] = f.value;
                }
            });

            onChange?.(params);
        },
        [onChange],
    );

    return (
        <FilterStrip
            className={coalesceClassNames('BackendFilterStrip', className)}
            filterDefs={fieldDefs}
            allowReset={true}
            onRefresh={onRefresh}
            isRefreshing={isRefreshing}
            onChange={generateParams}
            filterResetComponent={({ resetFilters }) => (
                <div className="BackendFilterStrip__FilteredResetPanel">
                    <MyButton
                        className="BackendFilterStrip__ResetButton"
                        label="Reset filters"
                        buttonType="LinkButton"
                        onClick={resetFilters}
                    />
                </div>
            )}
        >
            {children}
        </FilterStrip>
    );
}
