import InventoryDetailsModal from 'features/inventory/components/InventoryDetailsModal/InventoryDetailsModal';
import inventoryApi from 'features/inventory/inventory.api';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryDetailsPage() {
    const { inventoryId = '' } = useParams();
    const navigate = useNavigate();

    const query = inventoryApi.useInventoryDetailQuery(inventoryId);
    usePageTitle(query.data?.partNumber);
    const [saveQuery, saveQueryState] = inventoryApi.useInventoryUpdateMutation();

    // These queries aren't used here directly
    // but we want to pre-load them before showing the modal
    const inventoryGroupsQuery = inventoryApi.useInventoryGroupListWithAttributesQuery();
    const suppliersQuery = inventoryApi.useSupplierListQuery();

    return inventoryId ? (
        <>
            <InventoryDetailsModal
                model={query.data}
                isLoading={
                    query.isLoading || inventoryGroupsQuery.isLoading || suppliersQuery.isLoading
                }
                isError={query.isError}
                onSave={saveQuery}
                isSaving={saveQueryState.isLoading}
                close={() => navigate('/inventory')}
            />
            <Outlet />
        </>
    ) : null;
}
