import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';
import z from 'zod';

export const AppSettingsSchema = z.object({
    schemaExtensions: z.record(
        z.nativeEnum(UserDefinedFieldEntityType),
        z.object({
            udfs: z.array(UserDefinedFieldSchema),
        }),
    ),
});

export type AppSettings = z.infer<typeof AppSettingsSchema>;
