import AnimatedRefreshButton from 'components/AnimatedRefreshButton/AnimatedRefreshButton';
import MyButton from 'components/MyButton/MyButton';
import MyMenuButton from 'components/MyMenuButton/MyMenuButton';
import { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import Icons from 'Icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { StrictUnion } from 'utils/typeHelpers';
import { MyMenuButtonItem } from '../MyMenuButton/MyMenuButton';
import AutocompleteFilter, { AutocompleteFilterConfig } from './Filters/AutocompleteFilter';
import DateFilter, { DateFilterConfig } from './Filters/DateFilter';
import SearchFilter, { SearchFilterConfig } from './Filters/SearchFilter';
import SelectFilter, { SelectFilterConfig } from './Filters/SelectFilter';
import TextFilter, { TextFilterConfig } from './Filters/TextFilter';
import ToggleFilter, { ToggleFilterConfig } from './Filters/ToggleFilter';
import './FilterStrip.scss';
import { FilterCompProps, FilterInstance } from './FilterTypes';

/** Union of filter configs for all the available filter types
 * Each filter type can define its own properties in addition to the base properties
 */
export type FilterStripConfig<T> = StrictUnion<
    (
        | TextFilterConfig
        | SearchFilterConfig
        | SelectFilterConfig
        | AutocompleteFilterConfig
        | DateFilterConfig
        | ToggleFilterConfig
    ) & {
        // additional base properties that self-reference this type
        // so cant be put in DataTableFilterConfigBase
        /** change event fired whenever the user changes the filter value */
        onChange?: (config: FilterStripConfig<T>, value: string) => void;
    }
>;

export default function FilterStrip<T, U extends FilterStripConfig<T>>({
    className,
    children,
    filterDefs,
    onChange,
    allowReset = true,
    onRefresh,
    isRefreshing,
    filterResetComponent,
}: {
    className?: string;
    children?: React.ReactNode;
    filterDefs: (false | U)[];
    onChange?: (filters: FilterInstance<U>[]) => void;
    allowReset?: boolean;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    filterResetComponent?: (props: { resetFilters: () => void }) => React.ReactNode;
}) {
    const [urlParams, setUrlParams] = useSearchParams({});
    const updateUrlParam = useCallback(
        (filter: FilterStripConfig<T>, val?: string) => {
            if (filter.urlParam) {
                if (!val || val === filter.defaultValue) {
                    urlParams.delete(filter.urlParam);
                } else {
                    urlParams.set(filter.urlParam, val);
                }
            }
        },
        [urlParams],
    );

    // Track filters that we want to show before the value has been selected
    const [visibleNonStickyFilters, setVisibleNonStickyFilters] = useState<
        FilterInstance<FilterStripConfig<T>>[]
    >([]);

    // If the filter definitions change, reset the visibleNonStickyFilters
    // Do this before we initialise the filters and apply any URL parameters
    useEffect(() => {
        setVisibleNonStickyFilters([]);
    }, [filterDefs]);

    const filters = useMemo(() => {
        const _filterDefs: U[] = filterDefs.filter(f => !!f) as U[];
        return _filterDefs.map((f, i) => {
            const result: FilterInstance<typeof f> = {
                config: f,
                value: f.defaultValue || '',
                key: `${f.label}-${i}`,
            };
            // create a copy of options array for select filters
            if (result.config.type === 'select' || result.config.type === 'autocomplete') {
                result.config.options = result.config.options ? [...result.config.options] : [];
            }
            return result;
        });
    }, [filterDefs]);

    const [serializedFilterValues, setSerializedFilterValues] = useState<string>('');

    const updateSerialiseFilterValues = useCallback(
        (_filters: FilterInstance<FilterStripConfig<T>>[]) => {
            const values = JSON.stringify(_filters.map(f => f.value));
            setSerializedFilterValues(values);
        },
        [],
    );

    // When the url params change, set the filter values and update the visible non-sticky filters
    useEffect(
        () => {
            filters.forEach(f => {
                const urlParamValue = f.config.urlParam ? urlParams.get(f.config.urlParam) : null;

                // If the filter is allowed to be set via url params, but none is provided, set to default
                if (f.config.urlParam && !urlParamValue) {
                    f.value = f.config.defaultValue || '';
                } else if (f.config.type === 'select' || f.config.type === 'autocomplete') {
                    // apply urlParamValue only if a valid option exists
                    // this avoids console errors about out-of-range values
                    if (
                        urlParamValue &&
                        f.config.options?.some(
                            (o: MySelectInputOption) => o.value === urlParamValue,
                        )
                    ) {
                        f.value = urlParamValue;
                    }
                } else {
                    // apply urlParamValue
                    f.value = urlParamValue || f.value;
                }
            });

            // Add new filters to the visibleNonStickyFilters
            const newVisibleNonStickyFilters = [...visibleNonStickyFilters];
            filters.forEach(f => {
                if (
                    !f.config.isSticky &&
                    f.value &&
                    !visibleNonStickyFilters.some(vnsf => vnsf.key === f.key)
                ) {
                    newVisibleNonStickyFilters.push(f);
                }
            });
            setVisibleNonStickyFilters(newVisibleNonStickyFilters);

            updateSerialiseFilterValues(filters);
        },
        // Don't trigger update if the visibleNonStickyFilters change
        // as this will cause infinite loops
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [urlParams, filters],
    );

    const handleFilterChanged = useCallback(
        (filter: FilterInstance<FilterStripConfig<T>>) => {
            // update url params
            filter.config.onChange?.(filter.config, filter.value);
            if (filter.config.urlParam) {
                const oldParam = urlParams.get(filter.config.urlParam ?? '');
                if (oldParam !== filter.value) {
                    updateUrlParam(filter.config, filter.value);
                    setUrlParams(urlParams, { replace: true });
                }
            }
            // Update the filter values so that changes can be applied if needed
            updateSerialiseFilterValues(filters);
        },
        [updateSerialiseFilterValues, setUrlParams, updateUrlParam, urlParams, filters],
    );

    const hasHiddenFilters = useMemo(
        () => filters.some(f => !f.config.isSticky && !visibleNonStickyFilters.includes(f)),
        [filters, visibleNonStickyFilters],
    );

    const toggleNonStickyFilterVisibility = useCallback(
        (filter: FilterInstance<FilterStripConfig<T>>) => {
            if (visibleNonStickyFilters.includes(filter)) {
                // remove the filter
                filter.value = '';
                if (filter.config.urlParam) {
                    updateUrlParam(filter.config, filter.value);
                    setUrlParams(urlParams, { replace: true });
                } else {
                    // Manually trigger applyFilters if there is no urlParam
                    updateSerialiseFilterValues(filters);
                }
                setVisibleNonStickyFilters(visibleNonStickyFilters.filter(p => p !== filter));
            } else {
                setVisibleNonStickyFilters([...visibleNonStickyFilters, filter]);
            }
        },
        [
            updateSerialiseFilterValues,
            setUrlParams,
            updateUrlParam,
            urlParams,
            visibleNonStickyFilters,
            filters,
        ],
    );

    const resetFilters = useCallback(() => {
        filters.forEach(f => {
            f.value = f.config.defaultValue || '';
            updateUrlParam(f.config, f.value);
        });
        setUrlParams(urlParams, { replace: true });
        setVisibleNonStickyFilters([]);
        updateSerialiseFilterValues(filters);
    }, [filters, setUrlParams, urlParams, updateSerialiseFilterValues, updateUrlParam]);

    const canResetFilters =
        allowReset && filters.some(f => (f.value ?? '') !== (f.config.defaultValue ?? ''));

    const overflowFilterMenuItems = useMemo(
        (): (MyMenuButtonItem | false)[] =>
            filters.map(
                f =>
                    !f.config.isSticky &&
                    !visibleNonStickyFilters.includes(f) && {
                        label: f.config.label,
                        onClick: () => toggleNonStickyFilterVisibility(f),
                    },
            ),
        [filters, visibleNonStickyFilters, toggleNonStickyFilterVisibility],
    );

    useEffect(() => {
        onChange?.(filters.filter(f => (f.value ?? '') !== ''));
    }, [serializedFilterValues, onChange, filters]);

    return (
        <div className={coalesceClassNames('FilterStrip', className)}>
            <div className="FilterStrip__Filters">
                {filters.map(
                    f =>
                        f.config.isSticky && (
                            <FilterComponent
                                key={f.key}
                                filter={f}
                                onChange={handleFilterChanged}
                            />
                        ),
                )}
                {children}
                {visibleNonStickyFilters.map(f => (
                    <div
                        className="FilterStrip__Filter--NonSticky"
                        key={f.key}
                    >
                        <FilterComponent
                            filter={f}
                            onChange={handleFilterChanged}
                        />
                        <MyButton
                            IconLeft={Icons.Close}
                            onClick={() => toggleNonStickyFilterVisibility(f)}
                            buttonType="Nude"
                            className="FilterStrip__Filter--NonSticky__CloseButton"
                            size="small"
                        />
                    </div>
                ))}
                {hasHiddenFilters && (
                    <MyMenuButton
                        title="Add another filter"
                        className="FilterStrip__AddFilterButton"
                        buttonType="Nude"
                        IconRight={Icons.Plus}
                        menuItems={overflowFilterMenuItems}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    />
                )}
                {onRefresh && (
                    <AnimatedRefreshButton
                        className={'FilterStrip__RefreshButton'}
                        onClick={onRefresh}
                        isRefreshing={isRefreshing}
                    />
                )}
            </div>
            {canResetFilters && filterResetComponent?.({ resetFilters })}
        </div>
    );
}

function FilterComponent<T>({ filter, onChange }: FilterCompProps<FilterStripConfig<T>>) {
    switch (filter.config.type) {
        case 'text':
            return (
                <TextFilter
                    filter={filter as FilterInstance<TextFilterConfig>}
                    onChange={onChange}
                />
            );
        case 'search':
            return (
                <SearchFilter
                    filter={filter as FilterInstance<SearchFilterConfig>}
                    onChange={onChange}
                />
            );
        case 'select':
            return (
                <SelectFilter
                    filter={filter as FilterInstance<SelectFilterConfig>}
                    onChange={onChange}
                />
            );
        case 'autocomplete':
            return (
                <AutocompleteFilter
                    filter={filter as FilterInstance<AutocompleteFilterConfig>}
                    onChange={onChange}
                />
            );
        case 'date':
            return (
                <DateFilter
                    filter={filter as FilterInstance<DateFilterConfig>}
                    onChange={onChange}
                />
            );
        case 'toggle':
            return (
                <ToggleFilter
                    filter={filter as FilterInstance<ToggleFilterConfig>}
                    onChange={onChange}
                />
            );
        default:
            throw new Error('No FilterComponent defined for filter type', filter.config);
    }
}
