import Env from 'config/Env';
import { rest } from 'msw';
import { fakeSettings } from './faker/AuthFaker';

let healthCheckCount = 0;
const BASE_URL = `${Env.API_BASE_URL}`;
// this mock is used to simulate the health check endpoint
// it will simulate a new version of the app after 5 calls for testing purposes
export const authHandlers = [
    rest.get(`/health.json`, (req, res, ctx) => {
        healthCheckCount++;
        const result = healthCheckCount >= 5 ? 'invalid' : process.env.REACT_APP_GIT_HASH;
        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                GIT_COMMIT: result,
            }),
        );
    }),

    rest.get(`${BASE_URL}/settings`, (req, res, ctx) => {
        const result = fakeSettings;
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];
