import { faker } from '@faker-js/faker';
import { SalesItem } from 'features/sales/models/SalesItem';
import { DateTime } from 'luxon';
import SalesOrderCoreStatus from '../enums/SalesOrderCoreStatus';
import { ProductConfiguration } from '../models/ProductConfiguration';
import { ProductOptionBase, ProductOptionFabric } from '../models/ProductOption';
import { ProductOptionGroup } from '../models/ProductOptionGroup';
import { SalesItemLabelConfig } from '../models/SalesItemLabelConfig';
import { SalesItemOptionsLegacy } from '../models/SalesItemOptionsLegacy';
import { SalesOrderDetail } from '../models/SalesOrderDetail';
import { SalesOrderGroup } from '../models/SalesOrderGroup';
import { SalesOrderLineStatus } from '../models/SalesOrderLineStatus';
import { SalesOrderStatus } from '../models/SalesOrderStatus';

faker.seed(42);
const getRandomCount = (min: number, max: number) => faker.number.int({ min, max });

export const fakeSalesOrderStatuses: SalesOrderStatus[] = [
    {
        id: 1,
        name: 'New',
        textColor: '#FFFFFF',
        backgroundColor: '#009eda',
        isActive: true,
        sortOrder: 1,
        coreStatus: SalesOrderCoreStatus.Unstarted,
    },
    {
        id: 2,
        name: 'In Progress',
        textColor: '#FFFFFF',
        backgroundColor: '#7ecfee',
        isActive: true,
        sortOrder: 2,
        coreStatus: SalesOrderCoreStatus.InProgress,
    },
    {
        id: 3,
        name: 'Completed',
        textColor: '#FFFFFF',
        backgroundColor: '#00c797',
        isActive: true,
        sortOrder: 3,
        coreStatus: SalesOrderCoreStatus.Completed,
    },
    {
        id: 4,
        name: 'on Hold',
        textColor: '#FFFFFF',
        backgroundColor: '#00c797',
        isActive: true,
        sortOrder: 3,
        coreStatus: SalesOrderCoreStatus.OnHold,
    },
    {
        id: 5,
        name: 'Cancelled',
        textColor: '#FFFFFF',
        backgroundColor: '#00c797',
        isActive: true,
        sortOrder: 3,
        coreStatus: SalesOrderCoreStatus.Cancelled,
    },
];

export const fakeSalesOrderLineStatuses: SalesOrderLineStatus[] = [
    {
        id: 1,
        name: 'New',
        textColor: '#FFFFFF',
        backgroundColor: '#009eda',
        isActive: true,
        sortOrder: 1,
    },
    {
        id: 2,
        name: 'Ready for scheduling',
        textColor: '#ffffff',
        backgroundColor: '#d83747',
        isActive: true,
        sortOrder: 2,
    },
    {
        id: 3,
        name: 'Scheduled',
        textColor: '#FFFFFF',
        backgroundColor: '#005d82',
        isActive: true,
        sortOrder: 3,
    },
    {
        id: 4,
        name: 'For delivery',
        textColor: '#404a00',
        backgroundColor: '#e2f479',
        isActive: true,
        sortOrder: 3,
    },
    {
        id: 5,
        name: 'Done',
        textColor: '#FFFFFF',
        backgroundColor: '#bfd730',
        isActive: true,
        sortOrder: 3,
    },
];

// generate more options
// most options should be base single option
// then sometimes multiple base options
// occasionally fabric option
export const generateFakeOptionGroup = (): ProductOptionGroup => {
    const val = faker.number.int({ min: 0, max: 9 });
    const group: ProductOptionGroup = {
        name: faker.lorem.word(),
        options: [],
    };
    // 10% chance of a showing quantity
    const showQuantity = faker.number.int({ min: 0, max: 9 }) === 0;
    const showPrice = faker.number.int({ min: 0, max: 9 }) === 0;

    if (val === 0) {
        // generate a single fabric option
        const fabricOption: ProductOptionFabric = {
            type: 'FABRIC',
            value: faker.lorem.word(),
            quantity: showQuantity ? faker.number.int({ min: 1, max: 10 }) : null,
            price: showPrice ? faker.number.float({ min: 1, max: 20, precision: 0.01 }) : null,
            itemFabricWidth: faker.number.int({ min: 10, max: 100 }),
            itemFabricRepeat: `${faker.number.int({ min: 10, max: 100 })}mm`,
            itemName: faker.commerce.productName(),
            brandName: faker.company.name(),
            color: faker.lorem.word(),
        };
        group.options.push(fabricOption);
    } else {
        // 80% chance of a single base option
        // else generate between 2 and 5 base options
        const isSingleOption = faker.number.int({ min: 0, max: 4 }) !== 0;

        faker.helpers.multiple(
            () => {
                const baseOption: ProductOptionBase = {
                    type: 'BASE',
                    value: faker.lorem.word(),
                    quantity: showQuantity ? faker.number.int({ min: 1, max: 10 }) : null,
                    price: showPrice
                        ? faker.number.float({ min: 1, max: 20, precision: 0.01 })
                        : null,
                };
                return group.options.push(baseOption);
            },
            { count: isSingleOption ? 1 : { min: 2, max: 5 } },
        );
    }

    return group;
};

export const generateFakeProductConfiguration = (): ProductConfiguration => {
    // 1 in 5 chance of no option groups
    const optionGroups =
        faker.number.int({ min: 0, max: 4 }) === 0
            ? []
            : faker.helpers.multiple(generateFakeOptionGroup, {
                  count: faker.number.int({ min: 10, max: 30 }),
              });

    return {
        windowGroupName: faker.helpers.maybe(() => faker.lorem.words()) ?? '',
        width: faker.number.int({ min: 10, max: 100 }),
        height: faker.number.int({ min: 10, max: 100 }),
        optionGroups,
    };
};

const generateFakeSalesItem = (itemNumber: number): SalesItem => {
    const status = faker.helpers.arrayElement(fakeSalesOrderLineStatuses);
    const configuration = generateFakeProductConfiguration();
    return {
        id: faker.number.int({ max: 999999 }),
        lineNumber: itemNumber,
        manufacturerOrderId: faker.number.int({ max: 999999 }), // TODO match the parent order
        quantity: faker.number.int({ min: 1, max: 10 }),
        lineSellPrice: faker.number.float({ min: 100, max: 200 }),
        lineCostPrice: faker.number.float({ min: 50, max: 100 }),
        lineTax: faker.number.float({ min: 50, max: 100 }),
        unitSellPrice: faker.number.float({ min: 100, max: 200 }),
        unitCostPrice: faker.number.float({ min: 50, max: 100 }),
        unitTax: faker.number.float({ min: 50, max: 100 }),
        orderWindowId: faker.number.int({ max: 999999 }),
        orderWindowProductId: faker.number.int({ max: 999999 }),
        orderLineStatusId: status.id,
        // windowNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        inventoryId: faker.number.int(),
        systemControlled: faker.datatype.boolean(),
        createdBy: faker.number.int(),
        notes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        updatedBy: faker.number.int(),
        pickingListLines: null,
        packingListLines: null,
        bomLines: null,
        context: {
            status,
            configuration,
            product: {
                name: 'Test Product',
                notes: '',
                hasWidth: true,
                hasHeight: true,
                hasQuantity: true,
            },
            orderWindow: {
                notes: 'Window Notes',
                orderId: faker.number.int(),
            },
            orderWindowProduct: {
                id: 1,
            },
            hasWorkItems: false, // TODO: implement in the future
        },
    };
};

export const generateFakeSalesOrder = (): SalesOrderDetail => {
    let itemNumber = 0;
    const dateReceived = DateTime.fromJSDate(faker.date.past({ years: 1, refDate: '2024-01-01' }));
    return {
        id: faker.string.uuid(),
        tuid: `O-${faker.string.numeric({ length: 6 })}`,
        legacyId: faker.number.int({ max: 999999 }),
        accountId: faker.number.int({ max: 999999 }),
        manufacturerReference: faker.string.numeric({ length: 6 }),
        totalCostPrice: faker.number.int({ min: 10, max: 2000 }),
        totalFreight: faker.number.int({ min: 1, max: 200 }),
        totalFreightOverride: 0,
        totalSellPrice: faker.number.int({ min: 10, max: 2000 }),
        totalTax: faker.number.int({ min: 1, max: 200 }),
        taxValidated: faker.datatype.boolean(),
        trackingInformation: null,
        shippingInstructions: faker.helpers.maybe(() => faker.word.words()) ?? '',
        allowDispatch: faker.datatype.boolean(),
        userDefinedFields: {},

        createdAt: dateReceived.toISO() ?? '',
        eta: null,
        orderStatusId: faker.number.int({ max: 999999 }),

        externalNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        internalNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        context: {
            isArchived: false,
            orderLines: faker.helpers.multiple(
                () => {
                    itemNumber++;
                    return generateFakeSalesItem(itemNumber);
                },
                { count: getRandomCount(1, 6) },
            ),
            shippingMethod: {
                name: 'Pickup',
            },
            shippingAddress: {
                fullAddress: faker.location.streetAddress({ useFullAddress: true }),
            },
            customerOrderGroup: {
                id: faker.number.int({ max: 999999 }),
                customerDetailsOverride: faker.helpers.maybe(() => faker.person.fullName()) ?? '',
                uniqueId: `O-${faker.string.numeric({ length: 6 })}`,
            },
            customerPurchaseOrder: {
                id: faker.number.int({ max: 999999 }),
                purchaseOrderId: faker.string.numeric({ length: 6 }),
                brandId: faker.number.int({ max: 99 }),
                categoryId: faker.number.int({ max: 99 }),
                splitId: faker.number.int({ max: 99 }),
                sidemark: `SM-${faker.string.numeric({ length: 8 })}`,
                notes: faker.helpers.maybe(() => faker.word.words()) ?? '',
            },
            customerConfig: {
                isInternal: faker.datatype.boolean(),
            },
            externalSiteContact: {
                firstName: faker.person.firstName(),
                lastName: faker.person.lastName(),
            },
            externalSiteBusiness: {
                name: faker.company.name(),
            },
            itemCount: getRandomCount(1, 6),
            mainCompany: {
                name: faker.company.name(),
            },
            status: faker.helpers.arrayElement(fakeSalesOrderStatuses),
        },
    };
};

export const fakeSalesOrderGroups: SalesOrderGroup[] = [];
export const fakeSalesOrders = faker.helpers.multiple(generateFakeSalesOrder, { count: 100 });

export function getFakeSalesItemOptionsLegacy(): SalesItemOptionsLegacy {
    return {
        measurements: {
            width: 2450,
            height: 1250,
        },
        options: [
            {
                name: 'Hinge Door',
                value: 'Triple Lock',
            },
            {
                name: 'Hinge Door',
                value: 'Width up to 851 - 930 x up to 2100 high',
            },
            {
                name: 'Sliding Door Size',
                value: 'Single Lock',
            },
            {
                name: 'Sliding Door Size',
                value: 'Width up to 931 - 1100 x up to 2100 high',
            },
            {
                name: 'Flyscreen Door',
                value: 'Hinged',
            },
            {
                name: 'Flyscreen Door',
                value: 'Width up to 990 x up to 2400 high',
            },
            {
                name: 'Flyscreen',
                value: '400 x 600',
            },
            {
                name: 'Hinge Side',
                value: 'Right',
            },
            {
                name: 'Lock Side',
                value: 'Left',
            },
            {
                name: 'Lock Height',
                value: null,
            },
            {
                name: 'Colours',
                value: null,
            },
            {
                name: 'Extras',
                value: 'Triple Lock',
            },
            {
                name: 'Extras',
                value: 'Pet Door Petaway',
            },
            {
                name: 'Extras',
                value: 'Track top + bottom',
            },
            {
                name: 'Extras and Notes',
                value: 'Install services',
            },
            {
                name: 'Product Notes hidden on quote',
                value: null,
            },
        ],
        labels: [
            {
                name: 'Sample Label Rule',
                value: 'Yes',
            },
            {
                name: 'Test Number Label',
                value: 2049,
            },
            {
                name: 'Test String Label',
                value: 'Yes',
            },
            {
                name: 'Test Array String',
                value: ['1', '2', '3'],
            },
            {
                name: 'Test Array Number',
                value: [1, 2, 3],
            },
        ],
        synthesized_options: [
            {
                name: 'X_SIDEMARK',
                value: '',
            },
            {
                name: 'X_LOCATION',
                value: '',
            },
            {
                name: 'X_DESIGNATION',
                value: '',
            },
            {
                name: 'X_CUSTOMER_NAME',
                value: 'Testdealer Portal',
            },
            {
                name: 'X_PRODUCT_DESCRIPTION',
                value: 'Window A',
            },
            {
                name: 'X_ASSIGN_TO',
                value: '',
            },
            {
                name: 'X_LABEL',
                value: 'Window A',
            },
            {
                name: 'X_ROOM_WINDOW_NAME',
                value: '',
            },
            {
                name: 'X_ORDER_SIDEMARK',
                value: 'TEST3',
            },
            {
                name: 'X_ETA',
                value: '2024-06-29',
            },
            {
                name: 'X_STATUS',
                value: 'New',
            },
            {
                name: 'X_CUSTOMER_STATUS',
                value: 'New Order',
            },
        ],
        secondary_product_options: [
            {
                name: 'Really?',
                value: 'Yes',
            },
            {
                name: 'You?',
                value: 'Yes',
            },
        ],
    };
}

export function getFakeSalesItemLabels(): SalesItemLabelConfig[] {
    return [
        {
            name: 'Test Label',
            id: 1,
            copies: 2,
            copies_user_overridable: true,
            height_in_units: 500.00037795813,
            width_in_units: 800.0006047245,
            units: 'px',
        },
        {
            name: 'Absolute units',
            id: 2,
            copies: 1,
            copies_user_overridable: true,
            height_in_units: 500,
            width_in_units: 800,
            units: 'px',
        },
        {
            name: 'A4',
            id: 3,
            copies: 2,
            copies_user_overridable: false,
            height_in_units: 297,
            width_in_units: 210,
            units: 'mm',
        },
        {
            name: 'A4 Rounding',
            id: 8,
            copies: 1,
            copies_user_overridable: true,
            height_in_units: 297.0012,
            width_in_units: 210.00002,
            units: 'mm',
        },
        {
            name: 'A4 Inches',
            id: 40,
            copies: 1,
            copies_user_overridable: true,
            height_in_units: 11.6929133858,
            width_in_units: 8.2677165354,
            units: 'in',
        },
    ];
}
