import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { api, ApiTagType } from 'services/api';
import { AttributeListResult, AttributeListResultSchema } from './models/AttributeListResult';
import { Inventory, InventorySchema } from './models/Inventory';
import {
    InventoryGroupListResult,
    InventoryGroupListResultSchema,
} from './models/InventoryGroupListResult';
import {
    InventoryGroupListWithAttributesResult,
    InventoryGroupListWithAttributesResultSchema,
} from './models/InventoryGroupListWithAttributesResult';
import {
    InventoryLocationsResult,
    InventoryLocationsResultSchema,
} from './models/InventoryLocationsResult';
import {
    InventoryItemMovement,
    InventoryItemMovementSchema,
} from './models/InventoryMovementBatch';

import { dateFilterOptionsAny, DateFilterValuesPast } from 'models/DateFilterOption';
import z from 'zod';
import InventoryReceiptStatus from './enums/InventoryReceiptStatus';
import {
    InventoryLocationJoinDetail,
    InventoryLocationJoinDetailSchema,
} from './models/InventoryLocationJoinDetail';
import { InventoryMeasure, InventoryMeasureSchema } from './models/InventoryMeasure';
import { InventoryMeasureCreate } from './models/InventoryMeasureCreate';
import { InventoryMeasureUpdate } from './models/InventoryMeasureUpdate';
import { InventoryMovementCreateRequest } from './models/InventoryMovementCreateRequest';
import { InventoryReceipt, InventoryReceiptSchema } from './models/InventoryReceipt';
import {
    InventoryReceiptDetail,
    InventoryReceiptDetailSchema,
} from './models/InventoryReceiptDetail';
import {
    InventoryReceiptListResult,
    InventoryReceiptListResultSchema,
} from './models/InventoryReceiptListResult';
import { InventoryReceiptUpdate } from './models/InventoryReceiptUpdate';
import { InventorySearchResult, InventorySearchResultSchema } from './models/InventorySearchResult';
import { InventoryTotals, InventoryTotalsSchema } from './models/InventoryTotals';
import { Location, LocationSchema } from './models/Location';
import { LocationInventory, LocationInventorySchema } from './models/LocationInventory';
import { Supplier, SupplierSchema } from './models/Supplier';
import { UnitOfMeasure, UnitOfMeasureSchema } from './models/UnitOfMeasure';

export type InventorySearchParams = DynamicQueryParams<{
    search: string;
    inventoryGroupId: string;
}>;

export type InventoryReceiptListParams = DynamicQueryParams<{
    search: string; // search by tuid
    locationId: string | null; // always a top-level parent location id
    dateRange: DateFilterValuesPast | null;
    status: InventoryReceiptStatus | null;
    isArchived: 'true' | 'false' | null;
}>;

function buildSearchCriteria(params: InventorySearchParams) {
    const searchObj = params.criteria?.search
        ? {
              type: 'group',
              operator: 'OR',
              left: {
                  type: 'group',
                  left: {
                      type: 'string',
                      propertyKey: 'PARTNUMBER',
                      value: params.criteria.search,
                      equality: 'CONTAINS',
                  },
                  right: {
                      type: 'string',
                      propertyKey: 'SUPPLIERPARTNUMBER',
                      value: params.criteria.search,
                      equality: 'CONTAINS',
                  },
                  operator: 'OR',
              },
              right: {
                  type: 'string',
                  propertyKey: 'DESCRIPTION',
                  value: params.criteria.search,
                  equality: 'CONTAINS',
              },
          }
        : undefined;

    const groupObj = params.criteria.inventoryGroupId
        ? {
              type: 'string',
              propertyKey: 'GROUP',
              value: params.criteria.inventoryGroupId,
              equality: 'EQUALS',
          }
        : undefined;

    const criteria = [searchObj, groupObj].filter(Boolean);
    return criteria;
}

const inventoryApi = api.injectEndpoints({
    endpoints: build => ({
        attributeList: build.query<AttributeListResult, void>({
            query: () => ({
                url: `/attribute`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => AttributeListResultSchema.parse(result),
        }),

        inventoryArchive: build.mutation<void, string>({
            query: id => ({
                url: `/inventory/${id}/archive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: [ApiTagType.Inventory],
        }),

        inventoryCreate: build.mutation<string, Inventory>({
            query: model => ({
                url: `/inventory/create`,
                method: 'POST',
                data: { ...model },
            }),
            transformResponse: (result: unknown) => {
                // return id of new record
                const schema = z.object({
                    data: InventorySchema,
                });
                return schema.parse(result).data.id;
            },
            invalidatesTags: [ApiTagType.Inventory],
        }),

        inventoryDetail: build.query<Inventory, string>({
            query: (id: string) => ({
                url: `/inventory/${id}/detail`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                // return id of new record
                const schema = z.object({
                    data: InventorySchema,
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.Inventory, id }],
        }),

        inventoryImport: build.mutation<void, File>({
            async queryFn(file, _queryApi, _extraOptions, fetchWithBQ) {
                const formData = new FormData();
                formData.append('file', file, file.name);
                const response = await fetchWithBQ({
                    url: `/inventory/import`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.error) {
                    return {
                        error: response.error,
                    };
                }
                return {
                    data: undefined,
                };
            },
        }),

        inventoryLocations: build.query<InventoryLocationsResult, string>({
            query: inventoryId => ({
                url: `/inventory/${inventoryId}/locations`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryLocationsResultSchema.parse(result),
            providesTags: (res, err, inventoryId) => [
                { type: ApiTagType.Inventory, id: inventoryId },
                { type: ApiTagType.InventoryStock, id: inventoryId },
            ],
        }),

        inventorySearch: build.query<InventorySearchResult, InventorySearchParams>({
            query: (params: InventorySearchParams) => ({
                url: '/inventory',
                method: 'POST',
                data: {
                    meta: {
                        limit: params.paging.limit,
                        skip: params.paging.skip,
                        includeArchived: false,
                        ordering: params.sort ? [params.sort] : [],
                        criteria: buildSearchCriteria(params),
                    },
                },
            }),
            transformResponse: (result: unknown) => InventorySearchResultSchema.parse(result),
            providesTags: [ApiTagType.Inventory],
        }),

        inventoryUpdate: build.mutation<void, Inventory>({
            query: model => ({
                url: `/inventory/update`,
                method: 'POST',
                data: model,
            }),
            transformResponse: () => undefined,
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    inventoryApi.util.updateQueryData('inventoryDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                dispatch(inventoryApi.util.invalidateTags([ApiTagType.Inventory]));
            },
        }),

        /** Get a summary of inventory stock totals by warehouse id - for display on the inventory list view */
        inventoryListTotals: build.query<
            InventoryTotals[],
            { parentLocationId: string; tenantInventoryIds: string[] }
        >({
            query: params => ({
                url: '/inventory/totals',
                method: 'POST',
                data: params,
            }),
            transformResponse: (result: unknown) => z.array(InventoryTotalsSchema).parse(result),
            providesTags: [ApiTagType.Inventory],
        }),

        inventoryGroupList: build.query<InventoryGroupListResult, void>({
            query: () => ({
                url: `/inventorygroup`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => InventoryGroupListResultSchema.parse(result),
        }),

        inventoryGroupListWithAttributes: build.query<InventoryGroupListWithAttributesResult, void>(
            {
                query: () => ({
                    url: `/inventorygroup/withattributes`,
                    method: 'POST',
                    data: {
                        data: null,
                        meta: {},
                    },
                }),
                transformResponse: (result: unknown) =>
                    InventoryGroupListWithAttributesResultSchema.parse(result),
            },
        ),

        /** Get all movements for a single inventory item */
        inventoryItemMovements: build.query<InventoryItemMovement[], string>({
            query: inventoryId => ({
                url: `/inventory/${inventoryId}/movements`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(InventoryItemMovementSchema).parse(result),
            providesTags: (req, res, inventoryId) => [
                { type: ApiTagType.Inventory, id: inventoryId },
                { type: ApiTagType.InventoryStock, id: inventoryId },
            ],
        }),

        /** Create a new movement for a single inventory item */
        inventoryMovementCreate: build.mutation<void, InventoryMovementCreateRequest>({
            query: data => ({
                url: `/inventorymovement/create`,
                method: 'POST',
                data,
            }),
            invalidatesTags: [ApiTagType.InventoryStock],
        }),

        unitOfMeasureList: build.query<UnitOfMeasure[], void>({
            query: () => {
                return {
                    url: `/unitsofmeasure`,
                    method: 'GET',
                };
            },
            transformResponse: (result: unknown) => {
                const schema = z.array(UnitOfMeasureSchema);
                return schema.parse(result);
            },
        }),

        inventoryMeasureList: build.query<InventoryMeasure[], string>({
            query: (id: string) => {
                return {
                    url: `/inventory/${id}/measures`,
                    method: 'GET',
                };
            },
            transformResponse: (result: unknown) => z.array(InventoryMeasureSchema).parse(result),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                dispatch(
                    inventoryApi.util.invalidateTags([{ type: ApiTagType.InventoryMeasure, id }]),
                );
                await queryFulfilled;
            },
            providesTags: (req, res, inventoryId) => [
                { type: ApiTagType.Inventory, id: inventoryId },
                { type: ApiTagType.InventoryMeasure, id: inventoryId },
            ],
        }),

        inventoryMeasureCreate: build.mutation<undefined, InventoryMeasureCreate>({
            query: model => ({
                url: `/inventory/${model.inventoryId}/measures/create`,
                method: 'POST',
                data: model,
            }),
            invalidatesTags: (result, err, model) => [
                { type: ApiTagType.InventoryMeasure, id: model.inventoryId },
            ],
        }),

        inventoryMeasureUpdate: build.mutation<undefined, InventoryMeasureUpdate>({
            query: ({ inventoryId, ...model }) => ({
                url: `/inventory/${inventoryId}/measures/update`,
                method: 'POST',
                data: model,
            }),

            invalidatesTags: (result, err, model) => [
                { type: ApiTagType.InventoryMeasure, id: model.inventoryId },
            ],
        }),

        inventoryMeasureArchive: build.mutation<
            undefined,
            { measureId: string; inventoryId: string }
        >({
            query: ({ inventoryId, measureId }) => ({
                url: `/inventory/${inventoryId}/measures/archive/${measureId}`,
                method: 'POST',
            }),

            invalidatesTags: (result, err, model) => [
                { type: ApiTagType.InventoryMeasure, id: model.inventoryId },
            ],
        }),

        inventoryMeasureUnarchive: build.mutation<
            undefined,
            { measureId: string; inventoryId: string }
        >({
            query: ({ inventoryId, measureId }) => ({
                url: `/inventory/${inventoryId}/measures/unarchive/${measureId}`,
                method: 'POST',
            }),

            invalidatesTags: (result, err, model) => [
                { type: ApiTagType.InventoryMeasure, id: model.inventoryId },
            ],
        }),

        inventoryReceiptList: build.query<InventoryReceiptListResult, InventoryReceiptListParams>({
            query: (params: InventoryReceiptListParams) => {
                let dateReceivedFrom = null;
                let dateReceivedTo = null;
                if (params.criteria.dateRange) {
                    const opt = dateFilterOptionsAny.find(
                        o => o.value === params.criteria.dateRange,
                    );
                    if (opt) {
                        dateReceivedFrom = opt.minDate.toISODate();
                        dateReceivedTo = opt.maxDate.toISODate();
                    }
                }
                return {
                    url: '/inventoryreceipt',
                    method: 'POST',
                    data: {
                        search: params.criteria.search,
                        locationId: params.criteria.locationId || null,
                        dateReceivedFrom,
                        dateReceivedTo,
                        status: params.criteria.status || null,
                        isArchived: params.criteria.isArchived === 'true',
                        meta: {
                            limit: params.paging.limit,
                            skip: params.paging.skip,
                            ordering: params.sort ? [params.sort] : [],
                        },
                    },
                };
            },
            transformResponse: (result: unknown) => InventoryReceiptListResultSchema.parse(result),
            providesTags: [ApiTagType.InventoryReceipt],
        }),

        inventoryReceiptCreate: build.mutation<string | undefined, InventoryReceipt>({
            query: model => ({
                url: `/inventoryreceipt/create`,
                method: 'POST',
                data: {
                    locationId: model.locationId,
                    notes: model.notes,
                    dateReceived: model.dateReceived,
                    inventory: [],
                },
            }),
            transformResponse: (result: unknown) =>
                // return id of new record
                InventoryReceiptSchema.parse(result).id,
            invalidatesTags: [ApiTagType.InventoryReceipt],
        }),

        inventoryReceiptDetail: build.query<InventoryReceiptDetail, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryReceiptDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.InventoryReceipt, id }],
        }),

        inventoryReceiptUpdate: build.mutation<void, InventoryReceiptUpdate>({
            query: model => ({
                url: `/inventoryreceipt/${model.id}/update`,
                method: 'POST',
                data: model,
            }),
            transformResponse: () => undefined,
            invalidatesTags: [ApiTagType.InventoryReceipt],
        }),

        inventoryReceiptProcess: build.mutation<void, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}/process`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: [ApiTagType.InventoryReceipt],
        }),

        inventoryReceiptArchive: build.mutation<void, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}/archive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: [ApiTagType.InventoryReceipt],
        }),

        inventoryReceiptUnarchive: build.mutation<void, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}/unarchive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: [ApiTagType.InventoryReceipt],
        }),

        locationDetail: build.query<Location, string>({
            query: id => ({
                url: `/location/${id}/detail`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: LocationSchema,
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.Location, id }],
        }),

        locationInventory: build.query<LocationInventory[], string>({
            query: id => ({
                url: `/inventory/location/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => z.array(LocationInventorySchema).parse(result),
            providesTags: [ApiTagType.Inventory, ApiTagType.InventoryStock],
        }),

        locationInventoryJoinDetail: build.query<
            InventoryLocationJoinDetail,
            { inventoryId: string; locationId: string }
        >({
            query: args => ({
                url: `/inventory/${args.inventoryId}/location/${args.locationId}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryLocationJoinDetailSchema.parse(result),
            providesTags: (res, err, args) => [
                {
                    type: ApiTagType.Inventory,
                    id: args.inventoryId,
                },
                {
                    type: ApiTagType.InventoryStock,
                    id: args.inventoryId,
                },
            ],
        }),

        locationList: build.query<Location[], void>({
            query: () => ({
                url: `/location`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {
                        limit: 9999,
                        skip: 0,
                    },
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(LocationSchema),
                });
                return schema.parse(result).data;
            },
            providesTags: [ApiTagType.Location],
        }),

        supplierList: build.query<Supplier[], void>({
            query: () => ({
                url: `/supplier`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(SupplierSchema),
                });
                return schema.parse(result).data;
            },
        }),
    }),
});

export default inventoryApi;
