import authApi from 'features/auth/auth.api';
import { clearAuth, selectIsAuthenticated } from 'features/auth/auth.slice';
import { useEffect } from 'react';
import { api } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function useRestoreSession() {
    // Restore the session by loading the required data routes
    // There is no way to know if the current cookie is valid without calling /me
    const isAuthed = useAppSelector(selectIsAuthenticated);

    // List all the queries we need to load before rendering the app
    const meQuery = authApi.useMeQuery(undefined, { skip: isAuthed === false });
    const appSettingsQuery = authApi.useAppSettingsQuery(undefined, { skip: isAuthed === false });

    const dispatch = useAppDispatch();

    // Aggregate loading, error and success states from each query above
    const isLoading = meQuery.isLoading || appSettingsQuery.isLoading;
    const isError = meQuery.isError || appSettingsQuery.isError;
    const isSuccess = meQuery.isSuccess || appSettingsQuery.isSuccess;

    // Clear all api tags and logout when restore session fails
    useEffect(() => {
        if (isError && !isLoading) {
            dispatch(api.util.resetApiState());
            dispatch(
                clearAuth({
                    didLoginError: true,
                }),
            );
        }
    }, [isError, dispatch, isLoading]);

    // When unmounting, clear all api tags
    // this basically ensures that next time a component is mounted with this hook
    // that the queries will actually run anew
    useEffect(() => {
        return () => {
            dispatch(api.util.resetApiState());
        };
    }, [dispatch]);

    return { isLoading, isError, isSuccess };
}
