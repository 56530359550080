import Icons from 'Icons';
import inventoryApi from 'features/inventory/inventory.api';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getLocationPath } from 'utils/locationHelpers';
import './LocationDisplay.scss';

export default function LocationDisplay({
    locationId,
    className,
    isLink = false,
    showPath = true,
}: {
    locationId?: string;
    className?: string;
    isLink?: boolean;
    showPath?: boolean;
}) {
    const locationsQuery = inventoryApi.useLocationListQuery();

    const location = useMemo(() => {
        return locationsQuery.data?.find(l => l.id === locationId);
    }, [locationsQuery.data, locationId]);

    const path = useMemo(() => {
        if (!showPath) {
            return [];
        }
        const allLocations = locationsQuery.data || [];
        return location ? getLocationPath(location, allLocations) : [];
    }, [location, locationsQuery.data, showPath]);

    return (
        <div
            className={coalesceClassNames(
                'LocationDisplay',
                className,
                // locationId ? 'Link' : undefined,
            )}
            // to={locationId ? `/locations/${locationId}` : undefined}
        >
            {showPath &&
                path.map(l => (
                    <span
                        key={l.id}
                        className="LocationDisplay__Path"
                    >
                        {l.name}
                        <Icons.ChevronRight />
                    </span>
                ))}
            {isLink ? (
                <Link
                    className="Link LocationDisplay__Leaf"
                    to={`/locations/${location?.id}`}
                >
                    {location?.name}
                </Link>
            ) : (
                <span className="LocationDisplay__Leaf">{location?.name}</span>
            )}
            {/* {path.map(p => p.name).join(' / ')} / {location?.name} */}
        </div>
    );
}
