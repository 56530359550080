import { faker } from '@faker-js/faker';
import { generateFakeSalesOrder } from 'features/sales/faker/SalesFaker';
import { DateTime } from 'luxon';
import AccountingConnectionStatus from '../enums/AccountingConnectionStatus';
import AccountingConnectionType from '../enums/AccountingConnectionType';
import { AccountingConnection } from '../models/AccountingConnection';

faker.seed(42);
export const generateFakeSalesOrderAccountingConnection = (): AccountingConnection => {
    return {
        id: faker.string.uuid(),
        requestId: faker.string.uuid(),
        entityId: faker.string.uuid(),
        createdAt: DateTime.now()
            .minus({ seconds: faker.helpers.rangeToNumber({ min: 60, max: 604800 }) })
            .toISO() as string,
        details: [
            {
                label: 'Invoice Number',
                type: 'TEXT',
                value: `INV-${faker.string.numeric({ length: 6 })}`,
            },
            {
                label: 'Invoice Date',
                type: 'DATE',
                value: DateTime.now().toISO() as string,
            },
            {
                label: 'Amount Paid',
                type: 'CURRENCY',
                value: faker.number.float({ min: 100, max: 1000 }),
            },
            {
                label: 'Payment Method',
                type: 'TEXT',
                value: `Credit Card`,
            },
            {
                label: 'View in xero',
                type: 'LINK',
                value: `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=42693077-2f9f-40fa-8cda-bb173bea111d`,
            },
            {
                label: 'Contact phone',
                type: 'PHONE',
                value: faker.phone.number(),
            },
            {
                label: 'Contact email',
                type: 'EMAIL',
                value: faker.internet.email(),
            },
        ],
        externalId: null,
        raw: generateFakeSalesOrder(), // <-- just something with quite a lot of json
        schema: 'FAKESCHEMA_v1',
        status: faker.helpers.enumValue(AccountingConnectionStatus),
        summary: faker.word.words(faker.number.int({ min: 1, max: 20 })),
        type: faker.helpers.enumValue(AccountingConnectionType),
        updatedAt: DateTime.now().toISO() as string,
    };
};

export const fakeSalesOrderAccountingConnections = faker.helpers.multiple(
    generateFakeSalesOrderAccountingConnection,
    { count: 10 },
);
