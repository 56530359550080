import MyButton from 'components/MyButton/MyButton';
import Icons from 'Icons';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './AnimatedRefreshButton.scss';

export default function AnimatedRefreshButton({
    className,
    isRefreshing,
    onClick,
}: {
    className?: string;
    isRefreshing?: boolean;
    onClick?: () => void;
}) {
    return (
        <MyButton
            className={coalesceClassNames(
                'AnimatedRefreshButton',
                isRefreshing && 'refreshing',
                className,
            )}
            IconRight={Icons.Refresh}
            buttonType="LinkButton"
            onClick={onClick}
            title="Refresh"
        />
    );
}
