import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import { selectUdfs } from 'features/auth/auth.slice';
import StocktakeDetailModal from 'features/stocktake/components/StocktakeDetailModal/StocktakeDetailModal';
import stocktakeApi from 'features/stocktake/stocktake.api';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function StocktakeDetailPage() {
    const { stocktakeId } = useParams() as { stocktakeId: string };
    const navigate = useNavigate();

    const udfs = useAppSelector(selectUdfs(UserDefinedFieldEntityType.Stocktake));

    const query = stocktakeApi.useStocktakeDetailQuery(stocktakeId);
    usePageTitle(query.data?.number);
    const countSheetQuery = stocktakeApi.useCountSheetStatusesQuery(stocktakeId);

    return (
        <>
            <StocktakeDetailModal
                model={query.data}
                udfs={udfs}
                countSheetSummaries={countSheetQuery.data?.data}
                isLoading={query.isLoading}
                isError={query.isError}
                close={() => navigate(`/stocktake`)}
            />

            {query.data && (
                <Outlet
                    context={{
                        stocktake: query.data,
                    }}
                />
            )}
        </>
    );
}
