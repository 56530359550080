import { addListener } from '@reduxjs/toolkit';
import Alert, { AlertProps } from 'components/Dialogs/Alert';
import Confirm, { ConfirmProps } from 'components/Dialogs/Confirm';
import LoadingDialog, { LoadingDialogProps } from 'components/Dialogs/LoadingDialog';
import Toast, { ToastProps } from 'components/Dialogs/Toast';
import { clearAuth } from 'features/auth/auth.slice';
import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { showDialogAction } from 'store/showDialogAction';
import { generateShortUuid } from 'utils/helpers';
import './DialogManager.scss';

type DialogPromise = Promise<any> & {
    dialogId: string;
};

type DialogManagerState = {
    alert: (props: AlertProps) => Promise<void>;
    confirm: (props: ConfirmProps) => Promise<boolean>;
    toast: (props: ToastProps) => Promise<void>;
    error: (e: unknown) => Promise<void>;
    showLoadingWhile: <T>(promise: Promise<T>, props?: LoadingDialogProps) => Promise<T>;
    custom: <T>(DialogComp: FunctionComponent<T>, props: T) => Promise<any>;
};

export const DialogManagerContext = React.createContext<DialogManagerState>(
    {} as DialogManagerState,
);
export const useDialogManager = () => useContext(DialogManagerContext);

export default function DialogManager({ children }: { children: React.ReactNode }) {
    const dialogs = useRef(new Map<string, React.ReactNode>()).current;
    const forceUpdate = React.useReducer(() => ({}), {})[1];

    // only allow 1 toast and 1 error to display at any time
    const toastPromise = useRef<DialogPromise | null>(null);
    const errorPromise = useRef<DialogPromise | null>(null);

    const dispatch = useAppDispatch();

    const closeDialog = (id: string) => {
        dialogs.delete(id);
        forceUpdate();
    };

    // close all open dialogs when navigating to another route
    const location = useLocation();
    useEffect(() => {
        const ids = Array.from(dialogs.keys());
        ids.forEach(id => dialogs.delete(id));
        forceUpdate();
    }, [dialogs, forceUpdate, location, location.pathname]);

    const createDialog = <T,>(
        Type: FunctionComponent<T>,
        props: T,
        autoClosePromise?: Promise<void>,
    ) => {
        const id = generateShortUuid();

        const promise = new Promise<any>(resolve => {
            const dialog = (
                <Type
                    {...props}
                    key={id}
                    id={id}
                    close={resolve}
                />
            );

            dialogs.set(id, dialog);
            forceUpdate();

            // if an auto-close promise was provided,
            // resolve/close the modal when it resolves
            // this is mostly used for loading dialogs
            // which cant be manually closed
            if (autoClosePromise) {
                autoClosePromise.finally(() => {
                    resolve(undefined);
                });
            }
        }) as DialogPromise;

        promise.dialogId = id;

        // close dialog when resolved
        promise.then(() => {
            closeDialog(id);
        });

        return promise;
    };

    const alert = (props: AlertProps) => {
        return createDialog(Alert, props);
    };

    const confirm = (props: ConfirmProps) => {
        return createDialog(Confirm, props);
    };

    const toast = (props: ToastProps) => {
        // only allow 1 toast visible at a time
        // close any previous one
        if (toastPromise.current) {
            closeDialog(toastPromise.current.dialogId);
        }
        const promise = createDialog(Toast, props);
        toastPromise.current = promise;
        promise.finally(() => {
            toastPromise.current = null;
        });

        return promise;
    };

    /** Show a loading dialog while some code runs - auto closes when promise completes */
    const showLoadingWhile = (promise: Promise<any>, props?: LoadingDialogProps) => {
        const { dialogId } = createDialog(LoadingDialog, props);
        promise.finally(() => {
            closeDialog(dialogId);
        });
        return promise;
    };

    const error = (e: unknown) => {
        // eslint-disable-next-line no-console
        console.error(e);
        if (errorPromise.current) {
            // dont display another error on top of the current one
            return errorPromise.current;
        }

        // TODO display nice messages
        const title = 'Sorry, something went wrong';
        const message =
            'The server might be offline or experiencing issues, please try again later or contact technical support.';

        errorPromise.current = alert({
            title,
            message,
        });

        errorPromise.current.finally(() => {
            errorPromise.current = null;
        });

        return errorPromise.current;
    };

    const custom = <T,>(DialogComp: FunctionComponent<T>, props: T) => {
        return createDialog(DialogComp, props);
    };

    /** Listen to showDialogAction events from redux and display dialogs
     * Doing it this way means you can't wait for promise/results so only alert and toast are available */
    useEffect(() => {
        const showDialogUnsubscribe = dispatch(
            addListener({
                actionCreator: showDialogAction,
                effect: action => {
                    switch (action.payload.type) {
                        case 'toast':
                            toast(action.payload.props);
                            break;
                        case 'alert':
                        default:
                            alert(action.payload.props);
                            break;
                    }
                },
            }),
        );

        // close all dialogs when logging out
        // i.e when clearAuth is fired
        const didLogoutUnsubscribe = dispatch(
            addListener({
                actionCreator: clearAuth,
                effect: () => {
                    const ids = Array.from(dialogs.keys());
                    ids.forEach(id => closeDialog(id));
                },
            }),
        );

        return () => {
            dispatch(showDialogUnsubscribe);
            dispatch(didLogoutUnsubscribe);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DialogManagerContext.Provider
            value={{
                alert,
                confirm,
                toast,
                showLoadingWhile,
                error,
                custom,
            }}
        >
            {children}
            {Array.from(dialogs.values())}
        </DialogManagerContext.Provider>
    );
}
