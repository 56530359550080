import Icons from 'Icons';
import MyButton, { ButtonType, MyButtonLink } from 'components/MyButton/MyButton';
import SalesOrderStatusBadge from 'features/sales/components/SalesOrderStatusBadge/SalesOrderStatusBadge';
import SalesOrderCoreStatus from 'features/sales/enums/SalesOrderCoreStatus';
import WorkItemStepStatusBadge from 'features/workOrders/components/WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import StepCanTransition from 'features/workOrders/enums/StepCanTransition';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import WorkItemStepTransitionReason from 'features/workOrders/enums/WorkItemStepTransitionReason';
import WorkItemStepUiState, {
    WorkItemStepUiStateDisplay,
    computeWorkItemStepUiState,
} from 'features/workOrders/enums/WorkItemStepUiState';
import WorkflowStepType from 'features/workOrders/enums/WorkflowStepType';
import useStepsListNameParam from 'features/workstations/hooks/useStepsListNameParam';
import useStepsTableData from 'features/workstations/hooks/useStepsTableData';
import { StepTransitionParams } from 'features/workstations/models/StepTransitionParams';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import {
    selectCurrentWorkstation,
    selectPendingStepTransition,
} from 'features/workstations/workstations.slice';
import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkstationStepStatusArea.scss';

/** Appears in the details modal and displays the main status bar and any canTransition warnings */
export default function WorkstationStepStatusArea({
    model,
    doStepTransition,
}: {
    model: WorkstationStepDetail;
    doStepTransition?: (params: StepTransitionParams) => Promise<any>;
}) {
    const ownerStation = model.context.workstationLatest;

    const uiState = useMemo(
        () => computeWorkItemStepUiState(model.status, model.context.canTransition),
        [model.context.canTransition, model.status],
    );
    const uiActions = useMemo(() => getUiActions(model, uiState), [model, uiState]);

    const pendingStepTransition = useAppSelector(selectPendingStepTransition);

    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const [listName] = useStepsListNameParam();

    const [listData] = useStepsTableData({
        workstationId: currentWorkstation?.id,
        listName,
    });

    const [searchParams] = useSearchParams();

    const nextInQueueUrl = useMemo(() => {
        if (listName === 'ready') {
            // find the next item in the list, which can be actioned by the user
            // i.e not collapsed and canTransition == true
            const nextStep = listData.find(
                s => !s.isCollapsed && s.context.canTransition === StepCanTransition.True,
            );
            if (nextStep) {
                return `/workstations/${currentWorkstation?.id}/${nextStep.id}?${searchParams}`;
            }
        }
        return undefined;
    }, [currentWorkstation?.id, listData, listName, searchParams]);

    return (
        <div className="WorkstationStepStatusArea">
            {/* Can transition warnings */}
            {model.context.canTransition === StepCanTransition.FalseAnotherInProgress ? (
                uiActions.length > 0 && (
                    <div className="WorkstationStepStatusArea__Warning">
                        <p>Another step is currently in progress.</p>
                    </div>
                )
            ) : model.context.canTransition === StepCanTransition.FalseBlockingItems ? (
                <div className="WorkstationStepStatusArea__Warning">
                    <p>
                        This step has one or more dependent work items which are not yet completed
                    </p>
                    {(model.context.workOrderItemBlocking?.length ?? 0) > 0 && (
                        <ul>
                            {model.context.workOrderItemBlocking?.map(item => (
                                <li key={item.id}>
                                    {item.tuid} - {item.description}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ) : model.context.canTransition === StepCanTransition.FalseBlockingSteps ? (
                <div className="WorkstationStepStatusArea__Warning">
                    <p>This step has one or more dependent steps which are not yet completed</p>
                    {(model.context.workOrderItemStepsBlocking?.length ?? 0) > 0 && (
                        <ul>
                            {model.context.workOrderItemStepsBlocking.map(step => (
                                <li key={step.workflowStep.id}>
                                    {step.workflowStep.name}
                                    {' - '}
                                    {WorkItemStepUiStateDisplay.display(
                                        step.workOrderItemStep.status,
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ) : model.context.canTransition === StepCanTransition.FalseOwnedByAnotherStation ? (
                <div className="WorkstationStepStatusArea__Warning">
                    <p>
                        This step can't be actioned by you as it was picked up by another
                        workstation
                        {ownerStation && ` (${ownerStation.name})`}
                    </p>
                </div>
            ) : model.context.canTransition === StepCanTransition.FalseDispatchNotAllowed ? (
                <div className="WorkstationStepStatusArea__Warning">
                    <p>Dispatch is not allowed for this sales order</p>
                </div>
            ) : null}

            {/* Sales order status warnings */}
            {model?.context.salesOrder.context.status.coreStatus ===
                SalesOrderCoreStatus.Cancelled && (
                <div className="WorkstationStepStatusArea__Warning WorkstationStepStatusArea__Warning--SalesOrderStatus">
                    <span>
                        This sales order has been <strong>cancelled</strong>
                    </span>
                    <SalesOrderStatusBadge
                        statusId={model?.context.salesOrder.context.status.id}
                        size="small"
                    />
                </div>
            )}
            {model?.context.salesOrder.context.status.coreStatus ===
                SalesOrderCoreStatus.OnHold && (
                <div className="WorkstationStepStatusArea__Warning WorkstationStepStatusArea__Warning--SalesOrderStatus">
                    <span>
                        This sales order is currently <strong>on hold</strong>
                    </span>
                    <SalesOrderStatusBadge
                        statusId={model?.context.salesOrder.context.status.id}
                        size="small"
                    />
                </div>
            )}

            <div className={coalesceClassNames('WorkstationStepStatusArea__StatusBar', uiState)}>
                <div className="WorkstationStepStatusArea__StatusBar__Main">
                    <WorkItemStepStatusBadge
                        uiState={uiState}
                        lastTransitionReason={model.lastTransitionReason ?? null}
                        badgeType="title"
                    />
                </div>
                <div className="WorkstationStepStatusArea__StatusBar__Buttons">
                    {uiActions.map(action => (
                        <MyButton
                            key={action.key}
                            label={action.label}
                            buttonType={action.buttonType}
                            size="medium"
                            IconLeft={action.icon}
                            disabled={
                                !!pendingStepTransition ||
                                model.context.canTransition !== StepCanTransition.True
                            }
                            onClick={() => doStepTransition?.(action)}
                            isLoading={
                                pendingStepTransition?.to === action.to &&
                                pendingStepTransition.reason === action.reason
                            }
                        />
                    ))}

                    {/* Next in queue button - displayed when step is completed/skipped and you're currently on the ready queue page */}
                    {(model.status === WorkItemStepStatus.Completed ||
                        model.status === WorkItemStepStatus.Skipped) &&
                        nextInQueueUrl && (
                            <MyButton
                                label="Next in queue"
                                buttonType="Accent"
                                IconRight={Icons.ArrowNext}
                                href={nextInQueueUrl}
                                LinkComponent={MyButtonLink}
                            />
                        )}
                </div>
            </div>
        </div>
    );
}

type UiStepAction = {
    key: string;
    label: string;
    icon?: IconType;
    buttonType: ButtonType;
    to: WorkItemStepStatus;
    reason?: WorkItemStepTransitionReason;
};

/** Determine the available actions for this modal based on the state and any additional actions provided in the model */
function getUiActions(model: WorkstationStepDetail, uiState: WorkItemStepUiState): UiStepAction[] {
    const actions: UiStepAction[] = [];

    if (uiState === WorkItemStepUiState.ComingUp) {
        // no actions available while in 'coming up' state
        return [];
    }

    // include default actions based on current state
    // only if showDefaultActions is true
    if (model.context.workflowStepInstance.showDefaultActions) {
        if (model.context.workflowStep.stepType === WorkflowStepType.Default) {
            // Can Start?
            if (uiState === WorkItemStepUiState.Ready || uiState === WorkItemStepUiState.OnHold) {
                actions.push({
                    key: 'START',
                    label: model.status === WorkItemStepStatus.OnHold ? 'Resume' : 'Start',
                    icon: Icons.StepStart,
                    buttonType: 'Primary',
                    to: WorkItemStepStatus.InProgress,
                    reason: undefined,
                });
            }

            // Can Complete?
            if (uiState === WorkItemStepUiState.InProgress) {
                actions.push({
                    key: 'COMPLETE',
                    label: 'Complete',
                    icon: Icons.StepComplete,
                    buttonType: 'Accent',
                    to: WorkItemStepStatus.Completed,
                    reason: undefined,
                });
            }

            // Can Pause?
            if (uiState === WorkItemStepUiState.InProgress) {
                actions.push({
                    key: 'PAUSE',
                    label: 'Pause',
                    icon: Icons.StepPause,
                    buttonType: 'Hollow',
                    to: WorkItemStepStatus.OnHold,
                    reason: WorkItemStepTransitionReason.Pause,
                });
            }

            // Can Skip?
            if (uiState === WorkItemStepUiState.Ready || uiState === WorkItemStepUiState.OnHold) {
                actions.push({
                    key: 'SKIP',
                    label: 'Skip',
                    icon: Icons.StepSkip,
                    buttonType: 'Hollow',
                    to: WorkItemStepStatus.Skipped,
                    reason: undefined,
                });
            }
        }
        // TODO default actions for WorkflowStepType.Packing
    }

    // include any additional actions for the current status
    (model.context.workflowStepInstance.additionalActions || [])
        .filter(a => a.validFrom.includes(model.status))
        .forEach((a, i) => {
            actions.push({
                key: `ADDITIONAL_${i}`,
                label: a.label,
                icon: undefined,
                buttonType: 'Hollow',
                to: a.to,
                reason: a.reason,
            });
        });

    return actions;
}
