import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import FilterStrip from 'components/FilterStrip/FilterStrip';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton from 'components/MyButton/MyButton';
import MyCalendarPicker from 'components/MyCalendarPicker/MyCalendarPicker';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import DndContainer from 'components/ReactSmoothDnd/DndContainer';
import { Schedule } from 'features/schedule/models/Schedule';
import { ScheduledWorkOrder } from 'features/schedule/models/ScheduledWorkOrder';
import scheduleApi from 'features/schedule/schedule.api';
import { setLastMoveToSchedule } from 'features/schedule/schedule.slice';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiTagType } from 'services/api';
import { DropResult } from 'smooth-dnd';
import { useAppDispatch } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { dateIsPast } from 'utils/dateHelpers';
import { isNone } from 'utils/helpers';
import ScheduleWorkTable from '../ScheduleWorkTable/ScheduleWorkTable';
import './ScheduleMain.scss';

const DATE_DEFAULT = DateTime.now().startOf('week');

export default function ScheduleMain() {
    const [dateParam, setDateParam] = useUrlQueryState('date', {
        defaultValue: DATE_DEFAULT.toISODate() as string,
    });
    const selectedDate = useMemo(() => DateTime.fromISO(dateParam), [dateParam]);

    const dateFrom = useMemo(() => selectedDate.startOf('week'), [selectedDate]);
    const dateTo = useMemo(() => dateFrom.plus({ days: 6 }), [dateFrom]);

    const dateFromStr = useMemo(() => dateFrom.toFormat('yyyy-MM-dd'), [dateFrom]);
    const dateToStr = useMemo(() => dateTo.toFormat('yyyy-MM-dd'), [dateTo]);

    const isDateThisWeek = useMemo(
        () => selectedDate.hasSame(DateTime.now(), 'week'),
        [selectedDate],
    );

    const [isMovingDates, setIsMovingDates] = useState(true);

    const dispatch = useAppDispatch();
    const [assignMutation] = scheduleApi.useScheduleAssignWorkOrdersMutation();

    const scheduleQuery = scheduleApi.useScheduleListWithWorkOrdersQuery(
        {
            dateFrom: dateFromStr,
            dateTo: dateToStr,
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );

    useEffect(() => {
        if (!scheduleQuery.isFetching) {
            setIsMovingDates(false);
        }
    }, [scheduleQuery.isFetching]);

    /** Move work orders to the given schedule
     * @param schedule the schedule to move items to
     * @param workOrders an array of WorkOrders to be moved
     * @param index optional param - the index to put the new items at. If not provided then the items will be scheduled last. */
    const handleAssignToSchedule = (
        schedule: Schedule,
        workOrders: ScheduledWorkOrder[],
        sortOrder?: number,
    ) => {
        // for concurrency - find the id of the item with the provided `sortOrder`
        const sortOrderWorkOrderId = isNone(sortOrder)
            ? undefined
            : schedule.context?.scheduleWorkOrders.find(w => w.sortOrder === (sortOrder as number))
                  ?.id;

        if (!isNone(sortOrder) && !sortOrderWorkOrderId) {
            throw new Error(`handleAssignToSchedule: Invalid sortOrder '${sortOrder}'`);
        }

        // optimistic update of data
        // this would normally be done inside the api slice
        // but because we need access to dateFrom and dateTo its easier here
        dispatch(
            scheduleApi.util.updateQueryData(
                'scheduleListWithWorkOrders',
                {
                    dateFrom: dateFromStr,
                    dateTo: dateToStr,
                },
                draft => {
                    // targetList will be undefined if it is on a different page to sourceList
                    const targetList = draft.find(s => s.id === schedule.id)?.context
                        ?.scheduleWorkOrders;

                    const finalIndex = !targetList
                        ? 0
                        : isNone(sortOrder)
                        ? targetList.length
                        : targetList.findIndex(w => w.sortOrder === sortOrder);

                    // loop through work orders
                    workOrders.forEach(workOrder => {
                        const sourceSchedule = draft.find(
                            s => !!s.context?.scheduleWorkOrders.find(w => w.id === workOrder.id),
                        );
                        if (sourceSchedule) {
                            // remove from source list
                            const sourceList = sourceSchedule?.context?.scheduleWorkOrders ?? [];
                            const sourceIndex = sourceList.findIndex(w => w.id === workOrder.id);
                            if (sourceIndex > -1) {
                                sourceList.splice(sourceIndex, 1);
                            }
                        }
                    });

                    // append to targetList - this only happens if target is on the same page as source
                    if (targetList) {
                        targetList.splice(finalIndex, 0, ...workOrders);
                    }
                },
            ),
        );

        assignMutation({
            scheduleId: schedule.id,
            scheduleDate: schedule.date,
            workOrders: workOrders.map(w => w.id),
            sortOrder,
            sortOrderWorkOrderId,
            position: isNone(sortOrder) ? undefined : 'ABOVE', // TODO BELOW?
        });

        dispatch(setLastMoveToSchedule(schedule));
    };

    const goToDate = (date: DateTime) => {
        setIsMovingDates(true);
        setDateParam(date.toISODate() as string);
    };

    const isLoading = isMovingDates && scheduleQuery.isFetching;

    const invalidateTags = useApiTagInvalidate([ApiTagType.Schedule]);
    const refreshData = useCallback(() => {
        setIsMovingDates(true);
        invalidateTags();
    }, [invalidateTags]);

    return (
        <>
            <LayoutHeader>
                <PageHeader
                    className="ScheduleMain__PageHeader"
                    title="Schedule"
                />
                <FilterStrip
                    filterDefs={[]}
                    onRefresh={refreshData}
                    isRefreshing={scheduleQuery.isFetching}
                >
                    <div className="ScheduleMain__CalendarPickerWrapper">
                        <MyCalendarPicker
                            className="ScheduleMain__CalendarPicker"
                            selectionMode="week"
                            value={selectedDate}
                            onChange={goToDate}
                        />
                        <MyButton
                            className="ScheduleMain__ThisWeekButton"
                            label={isDateThisWeek ? 'This week' : 'Go to this week'}
                            buttonType="Hollow"
                            disabled={isDateThisWeek}
                            IconRight={isDateThisWeek ? Icons.Check : undefined}
                            onClick={() => goToDate(DateTime.now().startOf('week'))}
                        />
                    </div>
                </FilterStrip>
            </LayoutHeader>

            <LayoutBody className="ScheduleMain__DatesList">
                {scheduleQuery.currentData ? (
                    scheduleQuery.currentData.map((s, i) => (
                        <DateBlock
                            key={`${s.id}__${i}`}
                            schedule={s}
                            isLoading={isLoading}
                            onAssign={handleAssignToSchedule}
                        />
                    ))
                ) : isLoading ? (
                    <div className="ScheduleMain__DatesList__LoadingIndicator">
                        <MyLinearProgress delay={100} />
                    </div>
                ) : scheduleQuery.isError ? (
                    <ErrorContent className="ScheduleMain__DatesList__Error" />
                ) : null}
            </LayoutBody>
        </>
    );
}

function DateBlock({
    schedule,
    isLoading = false,
    onAssign,
}: {
    schedule: Schedule;
    isLoading?: boolean;
    onAssign: (schedule: Schedule, workOrders: ScheduledWorkOrder[], sortOrder?: number) => void;
}) {
    const [dateParam] = useUrlQueryState('date');
    const [highlightId] = useUrlQueryState('highlight');

    const schedDate = useMemo(() => DateTime.fromISO(schedule.date).toISODate(), [schedule.date]);
    const isDatePast = dateIsPast(DateTime.fromISO(schedule.date).endOf('day'));

    const dt = useMemo(() => DateTime.fromISO(schedule.date), [schedule.date]);
    const empty = !schedule.context?.scheduleWorkOrders.length;
    const isToday = dt.hasSame(DateTime.now(), 'day');

    // dates should be collapsed by default if they are
    // - in the past or empty
    // - and dont include the row highlighted by url params
    const isCollapsedDefault = (isDatePast || empty) && (!highlightId || dateParam !== schedDate);
    const [isCollapsed, setIsCollapsed] = useState(isCollapsedDefault);
    const [isDragging, setIsDragging] = useState(false);

    const handleDrop = (result: DropResult) => {
        if (!isNone(result.addedIndex)) {
            const i = result.addedIndex as number;
            const sortOrder = schedule.context?.scheduleWorkOrders[i]?.sortOrder ?? undefined;
            setIsCollapsed(false);
            onAssign(schedule, [result.payload], sortOrder);
        }
    };

    const expandTimeout = React.useRef<NodeJS.Timeout>();

    const expandOnDragEnter = useCallback(() => {
        expandTimeout.current = setTimeout(() => {
            setIsCollapsed(false);
            expandTimeout.current = undefined;
        }, 500);
    }, []);

    const cancelExpandOnDragEnter = useCallback(() => {
        if (expandTimeout.current) {
            clearTimeout(expandTimeout.current);
            expandTimeout.current = undefined;
        }
    }, []);

    /** Add up items for all work orders in schedule */
    const itemCount = useMemo(() => {
        const orders = schedule.context?.scheduleWorkOrders ?? [];
        const total = orders.reduce((sum, wo) => sum + wo.context.workOrderItems.length, 0);
        return total;
    }, [schedule.context?.scheduleWorkOrders]);

    return (
        <div
            className={coalesceClassNames(
                'ScheduleMain__DateBlock',
                isCollapsed && 'ScheduleMain__DateBlock--Collapsed',
                isLoading && 'ScheduleMain__DateBlock--Loading',
            )}
        >
            <div className="ScheduleMain__DateBlock__Header">
                <MyButton
                    className="ScheduleMain__DateBlock__Header__ExpandButton"
                    IconRight={isCollapsed ? Icons.ChevronDown : Icons.ChevronUp}
                    buttonType="Nude"
                    title="Expand / collapse"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
                <h2 className="ScheduleMain__DateBlock__Header__Title">
                    <span className="weekday">{dt.weekdayShort}</span>{' '}
                    <strong>{dt.toFormat('dd MMM')}</strong> {dt.toFormat('yyyy')}
                </h2>
                <Tooltip
                    title="Scheduled orders"
                    placement="top"
                    arrow
                >
                    <div
                        className={coalesceClassNames(
                            'ScheduleMain__DateBlock__Header__Counts',
                            empty && 'empty',
                        )}
                    >
                        <Icons.WorkOrder className="icon" />
                        {empty ? (
                            <>No work scheduled</>
                        ) : (
                            <strong>{schedule.context?.scheduleWorkOrders.length}</strong>
                        )}
                    </div>
                </Tooltip>
                {(schedule.context?.scheduleWorkOrders.length ?? 0) > 0 && (
                    <Tooltip
                        title="Total work items"
                        placement="top"
                        arrow
                    >
                        <div className="ScheduleMain__DateBlock__Header__Counts">
                            <Icons.WorkItem className="icon" />
                            <strong>{itemCount}</strong>
                        </div>
                    </Tooltip>
                )}

                {isToday && (
                    <span className="ScheduleMain__DateBlock__Header__TodayBadge">Today</span>
                )}
            </div>

            {isCollapsed && (
                // Add an extra drop zone when collapsed
                // when user hovers on this it will expand the block after short timeout
                // or user can drop here directly to add to the top of the list
                <div
                    className={coalesceClassNames(
                        'ScheduleMain__DateBlock__CollapsedDropZone',
                        isDragging && 'isDragging',
                    )}
                >
                    <DndContainer
                        groupName="ScheduleWorkOrders"
                        getChildPayload={i => schedule.context?.scheduleWorkOrders[i]}
                        onDrop={handleDrop}
                        onDragEnter={expandOnDragEnter}
                        onDragLeave={cancelExpandOnDragEnter}
                    ></DndContainer>
                </div>
            )}

            <ScheduleWorkTable
                schedule={schedule}
                isLoading={isLoading}
                isCollapsed={isCollapsed}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={() => setIsDragging(false)}
                onAssign={onAssign}
                handleDrop={handleDrop}
            />
        </div>
    );
}
