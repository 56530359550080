import { DateTime } from 'luxon';

const DATE_FILTER_VALUES_PAST = ['-30', '-7', 'today', 'this-month', 'this-year'] as const;
const DATE_FILTER_VALUES_FUTURE = ['today', '30', '7', 'this-month', 'this-year'] as const;

export type DateFilterValuesPast = typeof DATE_FILTER_VALUES_PAST[number];
export type DateFilterValuesFuture = typeof DATE_FILTER_VALUES_FUTURE[number];
export type DateFilterValuesAll = DateFilterValuesPast | DateFilterValuesFuture;

export type DateFilterOption = {
    label: string;
    value: DateFilterValuesAll;
    minDate: DateTime;
    maxDate: DateTime;
};

export const dateFilterOptionsAny: DateFilterOption[] = [
    {
        label: 'Last 30 days',
        value: '-30',
        minDate: DateTime.now().minus({ days: 30 }).startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Last 7 days',
        value: '-7',
        minDate: DateTime.now().minus({ days: 7 }).startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Today',
        value: 'today',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Next 7 days',
        value: '7',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().plus({ days: 7 }).endOf('day'),
    },
    {
        label: 'Next 30 days',
        value: '30',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().plus({ days: 30 }).endOf('day'),
    },
    {
        label: 'This month',
        value: 'this-month',
        minDate: DateTime.now().startOf('month'),
        maxDate: DateTime.now().endOf('month'),
    },
    {
        label: 'This year',
        value: 'this-year',
        minDate: DateTime.now().startOf('year'),
        maxDate: DateTime.now().endOf('year'),
    },
];

export const dateFilterOptionsPast = dateFilterOptionsAny.filter(o =>
    DATE_FILTER_VALUES_PAST.includes(o.value as any),
);

export const dateFilterOptionsFuture = dateFilterOptionsAny.filter(o =>
    DATE_FILTER_VALUES_FUTURE.includes(o.value as any),
);

export const getDateCriteriaFromValue = (value: string, propertyKey: string) => {
    const opt = dateFilterOptionsAny.find(o => o.value === value);
    if (!opt) {
        throw new Error(`Unknown date criteria value '${value}'`);
    }
    return {
        type: 'group',
        operator: 'AND',
        left: {
            type: 'date',
            propertyKey,
            equality: 'GREATERTHANEQUAL',
            value: opt.minDate,
        },
        right: {
            type: 'date',
            propertyKey,
            equality: 'LESSTHANEQUAL',
            value: opt.maxDate,
        },
    };
};
