import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import UserDefinedFieldValueType from 'enums/UserDefinedFieldValueType';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';

export const UserDefinedFieldSchema = z.object({
    entityType: z.nativeEnum(UserDefinedFieldEntityType),
    id: z.string(),
    name: z.string(),
    uniqueKey: z.string(),
    validationData: z.object({
        defaultValue: z.any().optional(),
        maxLength: z.number().optional(),
        maxSelectedOptions: z.number().optional(),
        maxValue: z.number().optional(),
        minSelectedOptions: z.number().optional(),
        minValue: z.number().optional(),
        options: z.array(z.string()).optional(),
        regex: z.string().optional(),
        required: z.boolean().optional(),
    }),
    valueType: z.nativeEnum(UserDefinedFieldValueType),
});

export type UserDefinedField = z.infer<typeof UserDefinedFieldSchema>;

export class UserDefinedFieldFactory {
    static create(data: Partial<UserDefinedField>) {
        const defaults: UserDefinedField = {
            id: '',
            entityType: UserDefinedFieldEntityType.Attribute,
            name: '',
            uniqueKey: '',
            validationData: {},
            valueType: UserDefinedFieldValueType.String,
        };

        const mergedData = mergeDeep(defaults, data);

        return UserDefinedFieldSchema.parse(mergedData);
    }
}

// Value hash to be used in models
export const UdfValueHashSchema = z
    .record(z.string(), z.union([z.string(), z.array(z.string())]))
    .default({});
export type UdfValueHash = z.infer<typeof UdfValueHashSchema>;
