import InventoryDetailsModal from 'features/inventory/components/InventoryDetailsModal/InventoryDetailsModal';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory, InventoryFactory } from 'features/inventory/models/Inventory';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryAddPage() {
    usePageTitle('Add');
    const navigate = useNavigate();

    const model = useRef(InventoryFactory.create({})).current;

    const [createQuery, createQueryState] = inventoryApi.useInventoryCreateMutation();

    const handleSave = async (data: Inventory) => {
        const result = (await createQuery(data)) as any;
        if (result.data) {
            navigate(`/inventory/${result.data}`);
        } else {
            throw new Error('Create Inventory result did not contain an id');
        }
    };

    return (
        <InventoryDetailsModal
            model={model}
            isNew={true}
            onSave={handleSave}
            isSaving={createQueryState.isLoading}
            close={() => navigate('/inventory')}
        />
    );
}
