import { DateTime } from 'luxon';
import { getDateCriteriaFromValue } from 'models/DateFilterOption';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { ApiTagType, api } from 'services/api';
import ShipmentStatus from './enums/ShipmentStatus';
import { Package, PackageSchema } from './models/Package';
import { PackageEntity, PackageEntitySchema } from './models/PackageEntity';
import { PackageListResult, PackageListResultSchema } from './models/PackageListResult';
import { Shipment, ShipmentSchema } from './models/Shipment';
import { ShipmentEntity, ShipmentEntitySchema } from './models/ShipmentEntity';
import { ShipmentListResult, ShipmentListResultSchema } from './models/ShipmentListResult';

export type ShipmentListParams = DynamicQueryParams<{
    search: string;
    date: string;
    hideCompleted: string;
}>;

export type PackageListParams = DynamicQueryParams<{
    search: string;
    warehouseId: string;
    locationId: string;
    hideShipped: 'HIDE' | 'SHOW';
}>;

function buildShipmentSearchCriteria(params: ShipmentListParams) {
    const result = [] as any[];

    if (params.criteria?.search) {
        result.push({
            type: 'string',
            propertyKey: 'NAME',
            value: params.criteria.search,
            equality: 'CONTAINS',
        });
    }

    if (params.criteria.hideCompleted === 'true') {
        result.push({
            type: 'group',
            operator: 'OR',
            left: {
                type: 'string',
                propertyKey: 'STATUS',
                value: ShipmentStatus.Open,
                equality: 'EQUALS',
            },
            right: {
                type: 'string',
                propertyKey: 'STATUS',
                value: ShipmentStatus.Locked,
                equality: 'EQUALS',
            },
        });
    }

    if (params.criteria.date) {
        const dateCrit = getDateCriteriaFromValue(params.criteria.date, 'DATE');
        result.push(dateCrit);
    }

    return result;
}

function buildPackageSearchCriteria(params: PackageListParams) {
    const result = [] as any[];

    if (params.criteria?.search) {
        result.push({
            type: 'string',
            propertyKey: 'DESCRIPTION',
            value: params.criteria.search,
            equality: 'CONTAINS',
        });
    }

    if (params.criteria?.warehouseId) {
        result.push({
            type: 'string',
            propertyKey: 'WAREHOUSEID',
            value: params.criteria.warehouseId,
            equality: 'EQUALS',
        });
    }

    if (params.criteria?.locationId) {
        result.push({
            type: 'string',
            propertyKey: 'LOCATIONID',
            value: params.criteria.locationId,
            equality: 'EQUALS',
        });
    }

    // if (params.criteria.hideShipped === 'HIDE') {
    //     result.push({
    //         type: 'string',
    //         propertyKey: 'STATUS',
    //         value: 'SHIPPED',
    //         equality: 'NOT_EQUALS',
    //     });
    // }

    return result;
}

const dispatchApi = api.injectEndpoints({
    endpoints: build => ({
        shipmentList: build.query<ShipmentListResult, ShipmentListParams>({
            query: params => ({
                url: '/shipment',
                method: 'POST',
                data: {
                    meta: {
                        limit: params.paging.limit,
                        skip: params.paging.skip,
                        includeArchived: false,
                        ordering: params.sort ? [params.sort] : [],
                        criteria: buildShipmentSearchCriteria(params),
                    },
                },
            }),
            transformResponse: (result: unknown) => ShipmentListResultSchema.parse(result),
            providesTags: [ApiTagType.Shipment],
        }),

        shipmentCreate: build.mutation<ShipmentEntity, ShipmentEntity>({
            query: model => ({
                url: `/shipment/create`,
                method: 'POST',
                data: {
                    date: DateTime.fromISO(model.date).toISO({ includeOffset: false }),
                    name: model.name,
                    notes: model.notes,
                    shipmentDays: model.shipmentDays,
                    status: model.status,
                },
            }),
            transformResponse: (result: unknown) => ShipmentEntitySchema.parse(result),
            invalidatesTags: [ApiTagType.Shipment],
        }),

        shipmentDetail: build.query<Shipment, string>({
            query: id => ({
                url: `/shipment/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => ShipmentSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.Shipment, id }],
        }),

        shipmentUpdate: build.mutation<void, ShipmentEntity>({
            query: model => ({
                url: `/shipment/${model.id}/update`,
                method: 'POST',
                data: {
                    date: model.date,
                    name: model.name,
                    notes: model.notes,
                    shipmentDays: model.shipmentDays,
                    status: model.status,
                },
            }),
            invalidatesTags: [ApiTagType.Shipment],
        }),

        packageList: build.query<PackageListResult, PackageListParams>({
            query: params => ({
                url: '/package',
                method: 'POST',
                data: {
                    meta: {
                        limit: params.paging.limit,
                        skip: params.paging.skip,
                        includeArchived: false,
                        ordering: params.sort ? [params.sort] : [],
                        criteria: buildPackageSearchCriteria(params),
                    },
                },
            }),
            transformResponse: (result: unknown) => PackageListResultSchema.parse(result),
            providesTags: [ApiTagType.Package],
        }),

        packageCreate: build.mutation<PackageEntity, PackageEntity>({
            query: model => ({
                url: `/package/create`,
                method: 'POST',
                data: {
                    shipmentId: model.shipmentId,
                    locationId: model.locationId,
                    description: model.description,
                    notes: model.notes,
                    weight: model.weight,
                    dimensions: model.dimensions,
                },
            }),
            transformResponse: (result: unknown) => PackageEntitySchema.parse(result),
            invalidatesTags: [ApiTagType.Package],
        }),

        packageDetail: build.query<Package, string>({
            query: id => ({
                url: `/package/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => PackageSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.Package, id }],
        }),

        packageUpdate: build.mutation<void, PackageEntity>({
            query: model => ({
                url: `/package/${model.id}/update`,
                method: 'POST',
                data: {
                    shipmentId: model.shipmentId,
                    locationId: model.locationId,
                    description: model.description,
                    notes: model.notes,
                    weight: model.weight,
                    dimensions: model.dimensions,
                },
            }),
            invalidatesTags: [ApiTagType.Package],
        }),
    }),
});

export default dispatchApi;
