import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import FrontendFilterStrip, {
    FrontendFilterBuilder,
} from 'components/FrontendFilterStrip/FrontendFilterStrip';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import { selectPrimaryLocationId, setPrimaryLocationId } from 'features/inventory/inventory.slice';
import { StocktakeStatusDisplay } from 'features/stocktake/enums/StocktakeStatus';
import { StocktakeTypeDisplay } from 'features/stocktake/enums/StocktakeType';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import workstationsApi from 'features/stocktake/stocktake.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { useAppSelector } from 'store/hooks';
import { isEmpty } from 'utils/helpers';
import StocktakeStatusBadge from '../StocktakeStatusBadge/StocktakeStatusBadge';
import './StocktakeTable.scss';

const COLUMN_DEFS = ColumnBuilder<Stocktake>()
    .column({
        label: 'Stocktake',
        key: 'number',
        getValue: item => item.number,
        isSortable: true,
        defaultSort: 'DESC',
    })
    .column({
        label: 'Warehouse',
        key: 'parentLocationName',
        getValue: item => item.parentLocationName,
        isSortable: true,
    })
    .column({
        label: 'Type',
        key: 'stocktakeType',
        getValue: item => StocktakeTypeDisplay.display(item.stocktakeType),
        isSortable: true,
    })
    .column({
        label: 'Status',
        key: 'status',
        isSortable: true,
        getValue: item => item.status,
        renderValue: (val, item) => (
            <div className="StocktakeTable__DataTable__Cell--status">
                <StocktakeStatusBadge
                    status={val}
                    isArchived={item.isArchived}
                />
            </div>
        ),
    })
    .column({
        label: 'Created',
        key: 'createdAt',
        isSortable: true,
        getValue: item => item.createdAt,
        renderValue: val => DateTime.fromISO(val).toFormat('dd/MM/yyyy'),
    })
    .column({
        label: 'Notes',
        key: 'notes',
        getValue: item => item.notes,
        renderValue: val => <div className="StocktakeTable__NotesDisplay">{val}</div>,
    })
    .build();

export default function StocktakeTable() {
    const query = workstationsApi.useStocktakeListQuery({ includeArchived: false });
    const refreshData = useApiTagInvalidate([ApiTagType.Stocktake]);

    const [filteredData, setFilteredData] = useState<Stocktake[]>();

    const dispatch = useDispatch();
    const defaultWarehouseId = useAppSelector(selectPrimaryLocationId);
    const warehouseFilterOptions = useWarehouseOptions();

    // highlight the currently open item id if any
    const { stocktakeId: activeItemId } = useParams();

    const tableFilters = useMemo(
        () =>
            FrontendFilterBuilder<Stocktake>()
                .filter({
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    getFields: item => [item.number, item.parentLocationName],
                })
                .filter(
                    !isEmpty(warehouseFilterOptions) && {
                        label: 'Warehouse',
                        type: 'select',
                        options: warehouseFilterOptions,
                        defaultValue: defaultWarehouseId,
                        getField: item => item.parentLocationId,
                        className: 'StocktakeTable__WarehouseFilter',
                        allowBlank: false,
                        onChange: (config, value) => {
                            if (warehouseFilterOptions?.some(o => o.value === value)) {
                                dispatch(setPrimaryLocationId(value));
                            }
                        },
                    },
                )
                .filter({
                    label: 'Type',
                    type: 'select',
                    options: StocktakeTypeDisplay.options,
                    defaultValue: '',
                    getField: item => item.stocktakeType,
                })
                .filter({
                    label: 'Status',
                    type: 'select',
                    options: StocktakeStatusDisplay.options,
                    defaultValue: '',
                    getField: item => item.status,
                })
                .build(),
        [defaultWarehouseId, dispatch, warehouseFilterOptions],
    );

    return (
        <>
            <LayoutHeader className="StocktakeTable__FilterBar">
                <FrontendFilterStrip
                    data={query.data?.data}
                    filters={tableFilters}
                    onChange={setFilteredData}
                    onRefresh={refreshData}
                    isRefreshing={query.isFetching}
                />
            </LayoutHeader>

            <LayoutBody>
                <DataTable
                    className="StocktakeTable__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={filteredData}
                    rowLinkTo={item => item.id}
                    rowIsHighlighted={item => item.id === activeItemId}
                    zebra="light"
                    useStickyHeader={true}
                    useFrontEndSorting={true}
                    isRefreshing={query.isFetching}
                    columns={COLUMN_DEFS}
                />
            </LayoutBody>
        </>
    );
}
