import { faker } from '@faker-js/faker';
import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import UserDefinedFieldValueType from 'enums/UserDefinedFieldValueType';
import { AppSettings } from '../models/AppSettings';

const countryOptions = ['USA', 'Australia', 'Canada'];

export const fakeSettings: AppSettings = {
    schemaExtensions: {
        ATTRIBUTE: {
            udfs: [],
        },
        CUSTOMER: {
            udfs: [
                {
                    name: 'reference',
                    uniqueKey: 'reference',
                    valueType: UserDefinedFieldValueType.String,
                    entityType: UserDefinedFieldEntityType.Customer,
                    validationData: {
                        defaultValue: 'Manufacturer name',
                        options: [],
                    },
                    id: faker.string.uuid(),
                },
                {
                    name: 'Country',
                    uniqueKey: 'country',
                    valueType: UserDefinedFieldValueType.Option,
                    entityType: UserDefinedFieldEntityType.Customer,
                    validationData: {
                        required: true,
                        defaultValue: countryOptions[0],
                        options: countryOptions,
                        minSelectedOptions: 1,
                        maxSelectedOptions: 1,
                    },
                    id: faker.string.uuid(),
                },
            ],
        },
        INVENTORY: {
            udfs: [],
        },
        PURCHASEORDER: {
            udfs: [],
        },
        SALESORDER: {
            udfs: [
                {
                    name: 'reference',
                    uniqueKey: 'reference',
                    valueType: UserDefinedFieldValueType.String,
                    entityType: UserDefinedFieldEntityType.SalesOrder,
                    validationData: {
                        defaultValue: 'man-web',
                        options: [],
                    },
                    id: faker.string.uuid(),
                },
                {
                    name: 'Country',
                    uniqueKey: 'country',
                    valueType: UserDefinedFieldValueType.Option,
                    entityType: UserDefinedFieldEntityType.SalesOrder,
                    validationData: {
                        required: true,
                        defaultValue: countryOptions[0],
                        options: countryOptions,
                        minSelectedOptions: 1,
                        maxSelectedOptions: 1,
                    },
                    id: faker.string.uuid(),
                },
                {
                    name: 'Boolean Test',
                    uniqueKey: 'booleanTest',
                    valueType: UserDefinedFieldValueType.Boolean,
                    entityType: UserDefinedFieldEntityType.SalesOrder,
                    validationData: {
                        required: true,
                        defaultValue: 'true',
                    },
                    id: faker.string.uuid(),
                },
            ],
        },
        WORKORDER: {
            udfs: [],
        },
        LOCATION: {
            udfs: [],
        },
        INVENTORYMOVEMENT: {
            udfs: [],
        },
        INVENTORYGROUP: {
            udfs: [],
        },
        STOCKTAKE: {
            udfs: [],
        },
        SUPPLIER: {
            udfs: [],
        },
        SCHEDULE: {
            udfs: [],
        },
        SHIPMENT: {
            udfs: [],
        },
        WORKFLOW: {
            udfs: [],
        },
        WORKSTATIONGROUP: {
            udfs: [],
        },
        WORKSTATION: {
            udfs: [],
        },
    },
};
