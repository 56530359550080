import { MyCheckboxTreeNode } from 'components/MyCheckboxTree/MyCheckboxTree';
import inventoryApi from 'features/inventory/inventory.api';
import { Location } from 'features/inventory/models/Location';
import { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import StocktakeTargetType from '../enums/StocktakeTargetType';
import { StocktakeTarget } from '../models/StocktakeTarget';

/** The node tree for the currently selected parent location */
export default function useLocationNodes({
    parentLocationId,
    targets,
    appendMissingIds,
}: {
    parentLocationId?: string;
    targets?: StocktakeTarget[];
    appendMissingIds?: boolean;
}) {
    const locationsQuery = inventoryApi.useLocationListQuery();
    return useLocationNodesPure({
        parentLocationId,
        targets,
        appendMissingIds,
        allLocs: locationsQuery.data ?? [],
    });
}

export function useLocationNodesPure({
    parentLocationId,
    targets,
    appendMissingIds,
    allLocs,
}: {
    parentLocationId?: string;
    targets?: StocktakeTarget[];
    appendMissingIds?: boolean;
    allLocs: Location[];
}) {
    /** The id of each selected location leaf node  */
    const selectedLocationIds = useMemo(
        () =>
            targets
                ?.filter(t => t.targetType === StocktakeTargetType.Location)
                .map(t => t.targetId),
        [targets],
    );

    // check the target id from the api look up locationsQuery and see if that id is present if not then append it to the nodes
    const childLocationNodes = useMemo(() => {
        const nodes = allLocs
            .filter(l => parentLocationId && l.parentLocationId === parentLocationId)
            .map(l => buildLocationNode(l, allLocs));

        nodes.sort((a, b) => typescriptNaturalSort(a.label, b.label));

        // append any selected ids that don't appear in the tree
        // i.e locations that have been deleted
        if (appendMissingIds) {
            selectedLocationIds
                ?.filter(id => !allLocs.find(l => l.id === id))
                .forEach(id =>
                    nodes.push({
                        id,
                        label: id,
                        nodes: [] as MyCheckboxTreeNode[] | undefined,
                    }),
                );
        }

        return nodes;
    }, [appendMissingIds, allLocs, parentLocationId, selectedLocationIds]);

    return [childLocationNodes, selectedLocationIds] as const;
}

/** Recursive func which builds up the node tree from the list of all locations */
function buildLocationNode(loc: Location, allLocations: Location[]) {
    const node: MyCheckboxTreeNode = {
        id: loc.id,
        label: loc.name,
        nodes: allLocations
            .filter(l => l.parentLocationId === loc.id)
            .map(l => buildLocationNode(l, allLocations)),
    };
    node.nodes?.sort((a, b) => typescriptNaturalSort(a.label, b.label));
    return node;
}
