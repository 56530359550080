import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyMoneyInput from 'components/MyMoneyInput/MyMoneyInput';
import MySwitchInput from 'components/MySwitchInput/MySwitchInput';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditDate from 'components/PropertyEditDate/PropertyEditDate';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditSwitch from 'components/PropertyEditSwitch/PropertyEditSwitch';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import UdfEdit from 'components/UdfEdit/UdfEdit';
import FeatureFlag from 'enums/FeatureFlag';
import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import { selectIsUS, selectUdfs } from 'features/auth/auth.slice';
import SalesOrderCoreStatus from 'features/sales/enums/SalesOrderCoreStatus';
import { useSalesOrderStatusOptions } from 'features/sales/hooks/useSalesOrderStatusOptions';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi from 'features/sales/sales.api';
import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatCurrency } from 'utils/helpers';
import { useFeatureFlagEnabled } from 'utils/posthogHelpers';
import './SalesOrderEditModal.scss';

export default function SalesOrderEditModal({
    model,
    close,
}: {
    model?: SalesOrder;
    close?: () => void;
}) {
    const isUS = useAppSelector(selectIsUS);

    /** AllowDispatch only shown when feature flag is turned on */
    const isAllowDispatchEnabled = !!useFeatureFlagEnabled(FeatureFlag.AllowDisPatch);

    const udfs = useAppSelector(selectUdfs(UserDefinedFieldEntityType.SalesOrder));

    const statusOptions = useSalesOrderStatusOptions({
        currentId: model?.orderStatusId,
    });

    const [updateMutation, updateMutationStatus] = salesApi.useSalesOrderUpdateMutation();
    const shouldUseNewUpdateRoute =
        useFeatureFlagEnabled(FeatureFlag.SalesOrderUpdateRoutesNew) ?? false;

    const handleSave = useCallback(
        async (editModel: SalesOrder) => {
            await updateMutation({ useNewRoute: shouldUseNewUpdateRoute, order: editModel });
            close?.();
        },
        [close, updateMutation, shouldUseNewUpdateRoute],
    );

    // before the order is cancelled we only show uncancelled statuses
    // after it has been cancelled we only show cancelled statuses
    const notCancelledStatusOptions = useMemo(() => {
        return statusOptions?.filter(o => o.coreStatus !== SalesOrderCoreStatus.Cancelled);
    }, [statusOptions]);

    const cancelledStatusOptions = useMemo(() => {
        return statusOptions?.filter(o => o.coreStatus === SalesOrderCoreStatus.Cancelled);
    }, [statusOptions]);

    const isAlreadyCancelled = useMemo(
        () => cancelledStatusOptions?.find(o => o.value === `${model?.orderStatusId}`),
        [cancelledStatusOptions, model?.orderStatusId],
    );

    const isSaving = updateMutationStatus.isLoading;
    return (
        <MyEditModal
            className="SalesOrderEditModal"
            title="Edit Sales Order"
            titleContext={`${model?.manufacturerReference}`}
            mobileTitle="Sales Order"
            close={close}
            isLoading={!statusOptions}
            // isError={isError}
            model={model}
            onSave={handleSave}
            isSaving={isSaving}
            readonly={false}
            fullHeight={false}
            editImmediately={true}
            containerSelector="#modal-root-top"
        >
            {({ editModel, isEditing, updateField }) => {
                const isFreightOverridden = typeof editModel?.totalFreightOverride === 'number';
                return (
                    <PropertyContainer
                        className="SalesOrderEditModal__PropertyContainer"
                        layout="table"
                    >
                        <PropertyEditSelect
                            label="Status"
                            value={`${editModel.orderStatusId}`}
                            onChange={(val: string) => {
                                const orderStatusId = parseInt(val, 10);
                                updateField({ orderStatusId });
                            }}
                            readonly={!isEditing}
                            options={
                                isAlreadyCancelled
                                    ? cancelledStatusOptions
                                    : notCancelledStatusOptions
                            }
                            disabled={isSaving}
                            validationRequired={true}
                        />

                        <PropertyEditDate
                            label="ETA"
                            value={editModel.eta ?? ''}
                            onChange={val => updateField({ eta: val || null })}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        <div className="PropertyContainerSpacer" />

                        <PropertyDisplay
                            label="Total freight"
                            value={
                                <span
                                    className={coalesceClassNames(
                                        'SalesOrderEditModal__TotalFreight',
                                        isFreightOverridden &&
                                            'SalesOrderEditModal__TotalFreight--overridden',
                                    )}
                                >
                                    {formatCurrency(editModel.totalFreight)}
                                </span>
                            }
                        />

                        <PropertyDisplay
                            label="Override total freight"
                            value={
                                <div className="SalesOrderEditModal__FreightOverride">
                                    <MySwitchInput
                                        checked={isFreightOverridden}
                                        handleInput={checked => {
                                            const totalFreightOverride = checked ? 0 : null;
                                            updateField({
                                                totalFreightOverride,
                                            });
                                        }}
                                    />
                                    {isFreightOverridden && (
                                        <MyMoneyInput
                                            value={
                                                editModel?.totalFreightOverride ??
                                                editModel?.totalFreight
                                            }
                                            handleInput={val => {
                                                if (val !== undefined) {
                                                    updateField({
                                                        totalFreightOverride: val,
                                                    });
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            }
                        />

                        <div className="PropertyContainerSpacer" />

                        {isUS && (
                            <PropertyEditSwitch
                                label="Tax validated"
                                checked={editModel.taxValidated}
                                onChange={val => updateField({ taxValidated: val })}
                            />
                        )}

                        {isAllowDispatchEnabled && (
                            <PropertyEditSwitch
                                label="Allow dispatch"
                                checked={editModel.allowDispatch}
                                onChange={val => updateField({ allowDispatch: val })}
                            />
                        )}

                        <div className="PropertyContainerSpacer" />

                        <PropertyEditText
                            label="Tracking information"
                            value={editModel.trackingInformation ?? ''}
                            onChange={val => updateField({ trackingInformation: val })}
                        />

                        <PropertyEditText
                            label="Internal Notes"
                            hint="Internal only"
                            value={editModel.internalNotes ?? ''}
                            onChange={val => updateField({ internalNotes: val })}
                            multiline={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        <PropertyEditText
                            label="External notes"
                            hint="Visible to customer"
                            value={editModel.externalNotes ?? ''}
                            onChange={val => updateField({ externalNotes: val })}
                            multiline={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        {/* User defined fields */}
                        {udfs?.map(udf => (
                            <UdfEdit
                                key={udf.id}
                                udf={udf}
                                value={editModel.userDefinedFields[udf.uniqueKey]}
                                onChange={val => {
                                    updateField({
                                        userDefinedFields: {
                                            [udf.uniqueKey]: val,
                                        },
                                    });
                                }}
                            />
                        ))}
                    </PropertyContainer>
                );
            }}
        </MyEditModal>
    );
}
